<template>
  <router-view />  
  <div  v-if="updateExists" >
    {{ $t('App.MajDispo') }}
    <button @click="refreshApp">{{ $t('App.MettreAJour') }}</button>
  </div>
</template>

<script>
import update from './mixins/update'
import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'

export default{
  beforeMount(){
    var pjson = require('../package.json');
    this.$store.dispatch('navigation/version', pjson.version)
    if (window.VUE_ENABLE_DDOG == "true") this.enableDatadog(pjson.version)
    
  },
  mixins: [update],
  methods: {
    enableDatadog(version) {
        datadogRum.init({
        applicationId: '2f52c455-b3d5-4b4b-b3c5-ab179cd6002c',
        clientToken: 'pub70c715f4d338a0691a93fd9870c11686',
        site: 'datadoghq.eu',
        service: 'surfcenter-bookapp',

        // Specify a version number to identify the deployed version of your application in Datadog 
        version: version,
        sampleRate: 100,
        premiumSampleRate: 100,
        trackInteractions: true,
        defaultPrivacyLevel: 'mask-user-input'
      });
      datadogLogs.setLoggerGlobalContext({ env:  window.VUE_DDOG_ENV })
      datadogRum.startSessionReplayRecording();
      }
    }
}
</script>

<style lang="scss">
  @import "scss/_normalize.scss";
  @import "scss/_fonts.scss";
  @import "scss/_mediaqueries.scss";
  @import "scss/_common.scss";
  @import "scss/_common-blocs.scss";
  @import "scss/_pages.scss";
  @import "scss/_calendar.scss";
  @import "scss/_spinner.scss";
</style>
