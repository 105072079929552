<template>
    <div class="body-popup body-popup-warning-message visible">
        <div class="popup-logo">
            <img src="@/assets/Logo-seul.svg" class="popup-logo--image" alt="logo">
            <p class="popup-logo--title">{{ $t("WarningPopup.Title1") }}<span>{{ $t("WarningPopup.Title2") }}</span></p>
        </div>

        <p class="text-gradient fontsize14 bold">{{ $t("WarningPopup.Important1") }}<br>{{ $t("WarningPopup.Important2") }}</p>

        <div class="popup-text">
            <div class="text">{{ $t("WarningPopup.Warning1") }}</div>
            <div class="description-text">{{ $t("WarningPopup.Warning2") }}<br/> <br/>{{ $t("WarningPopup.Warning3") }}</div>
        </div>

        <section class="pagination">
            <button @click="this.$emit('close')" class="pagination-button--return">
                <span class="icon icon-arrow-left1"></span>
                <p class="pagination-button--return__text">{{ $t("Mot.Retour") }}</p>
            </button>
            <router-link to="/formulaire" class="pagination-button--next bg-linear">
                <span class="pagination-button--next__text">{{ $t("Mot.Continuer") }}</span>
            </router-link>
        </section>
    </div>
</template>

<script>
    export default {}
</script>