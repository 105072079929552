import dayjs from 'dayjs'
import dateService from './date.service'

const isoWeek = require('dayjs/plugin/isoWeek')
dayjs.extend(isoWeek)

const isToday = require('dayjs/plugin/isToday')
dayjs.extend(isToday)

export default class CalendarService {
    
    constructor(){
        this.weekDays = dateService.getWeekDays()
        this.day = dayjs()
        this.week = this.createWeek()
        this.month = this.createMonth()
        this.calendar = this.createCalendar()
    }

    update(){
        this.week = this.createWeek()
        this.month = this.createMonth()
        this.calendar = this.createCalendar()
    }

    reset(){
        this.day = dayjs()
        this.update()
    }

    /* //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\// */

   /**
    * ---------
    * AFFICHAGE
    * ---------
    */



    /* //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\// */

   /**
    * --------------------
    * GESTION DES SEMAINES
    * --------------------
    */

    // getters
    weekInterval(){
        return {
            beginDate: this.week[0],
            endDate: this.week[this.week.length-1]
        }
    }

    // setters
    setNextWeek(){
        this.week = this.week.map((day) => day.add(1, 'week'))
    }
    setPreviousWeek(){
        this.week = this.week.map((day) => day.subtract(1, 'week'))
    }

    /* //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\// */

   /**
    * ----------------
    * GESTION DES MOIS
    * ----------------
    */

    // getters
    getPreviousMonth(){
        this.day = this.day.subtract(1, 'month')
        const previousMonth = this.createMonth()
        this.day = this.day.add(1, 'month')
        return previousMonth
    }
    getNextMonth(){
        this.day = this.day.add(1, 'month')
        const nextMonth = this.createMonth()
        this.day = this.day.subtract(1, 'month')
        return nextMonth
    }

    monthInterval(){
        return {
            beginDate: this.month[0].format("YYYY-MM-DD"),
            endDate: this.month[this.month.length-1].format("YYYY-MM-DD")
        }
    }

    // setters
    setNextMonth(){
        this.day = this.day.add(1, 'month')
        this.update()
    }
    setPreviousMonth(){
        this.day = this.day.subtract(1, 'month')
        this.update()
    }

    /* //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\// */

   /**
    * --------------------
    * CALENDRIER DYNAMIQUE
    * --------------------
    */

    createWeek(){        
        // Récupération du premier jour de la semaine sélectionnée
        let firstDayOfWeek
        for(let i=0; i<7; i++){
            const pastDay = this.day.subtract(i, 'day')
            if(pastDay.isoWeekday() === 1) {
                firstDayOfWeek = pastDay
            }
        }
        
        // Récupération de tous les autres jours de la semaine dans un array
        const week = []
        for(let i=0; i<7; i++){
            const dayOfWeek = firstDayOfWeek.add(i, 'day')
            week.push(dayOfWeek)
        }
        return week
    }

    createMonth(){
        // Récupération du premier jour du mois sélectionné
        let firstDayOfMonth = null
        for(let i=0; i<100; i++){
            const pastDay = this.day.subtract(i, 'day')
            if(pastDay.format('YYYY-MM-DD') === this.day.date(1).format('YYYY-MM-DD')) {
                firstDayOfMonth = pastDay
                this.day = firstDayOfMonth
            }
        }

        // Récupération de tous les autres jours du mois dans un array
        const month = []
        for(let i=0; i<100; i++){
            const dayOfMonth = firstDayOfMonth.add(i, 'day')
            if(dayOfMonth.format('YYYY-MM') === firstDayOfMonth.format('YYYY-MM')){
                month.push(dayOfMonth)
            }
        }
        return month
    }

    createCalendar(){
        const calendar = []
        let buffer = []

        // range les jours en rangée de semaine
        this.month.forEach((el, i) => {
            const day = {
                date: el,
                isToday: el.isToday(),  
            }

            buffer.push(day)

            if(i == this.month.length-1){
                calendar.push(buffer)
            }
            else if(el.isoWeekday() === 7){
                calendar.push(buffer)
                buffer = []
            }
        })

        // ajoute les jours du mois précédent
        const prevMonth = this.getPreviousMonth()
        while(calendar[0].length<7){
            const prevDay = prevMonth.pop()
            const day = {
                date: prevDay,
                fromOtherMonth: true
            }
            calendar[0].unshift(day)
        }
        
        // ajoute les jours du mois suivant        
        const nextMonth = this.getPreviousMonth()
        while(calendar[calendar.length-1].length<7){
            const nextDay = nextMonth.shift()
            const day = {
                date: nextDay,
                fromOtherMonth: true
            }
            calendar[calendar.length-1].push(day)
        }

        return calendar
    }
}