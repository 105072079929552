<template>     
    <router-link :to="returnLink" class="pagination-button--return">
      <span class="icon icon-arrow-left1"></span>
      <div class="pagination-button--return__text"> {{returnTitle}} </div>
    </router-link>
</template>

<script>
export default {
  name: "GoBack",
  props: {
    returnTitle: String,
    returnLink: String,
  },
};
</script>
