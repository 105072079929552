<template>
    <div class="body">
        <header class="components">
            <HeaderComponent :pageTitle="pageTitle"/>
        </header>

        <main class="components">            
            <h1 class="text need-mT4">
                {{ $t("Cancel.Dommage") }}
            </h1>
            
            <section class="need-mTB2 column row-center" >
                <p class="text">
                    {{ $t("Cancel.PaimentError") }}
                </p>
                <p class="text inline-block">
                    {{ $t("Cancel.SiQuestions") }} <a class="common-link" href="https://surfcenter.fr/foire-aux-questions/" target="_blank">{{ $t("Cancel.NotreFAQ") }}</a>
                </p>
            </section>
            <section class="pagination column relative">
                 <button  @click="retryPayment()"  class="pagination-button--next bg-linear relative need-mB5" :disabled="validationSpinner">
                    <div class="pagination-button--next__text">{{ $t("Cancel.Reessayer") }}</div>
                    <spinner-component class="absolute-btn-spinner" v-show="validationSpinner"></spinner-component>
                </button>
                <button @click="startNewCart()" class="pagination-button--next bg-linear need-mB5">
                    <div class="pagination-button--next__text">{{ $t("Cancel.AutreRésa") }}</div>
                </button>
                <!--a class="text" href="https://surfcenter.fr">Retourner sur le site</a-->
            </section>
        </main>
    </div>
</template>

<script>
import HeaderComponent from '@/components/elements/HeaderComponent.vue'
import { mapGetters } from 'vuex'
import surfapiService from '@/services/surfapi.service'
import SpinnerComponent from '@/components/elements/SpinnerComponent.vue'


export default {
    components: {
        HeaderComponent,
        SpinnerComponent
    }, data(){
        return {
            validationSpinner: false,
        }
    },  
    computed: {
        pageTitle(){return this.$t("Cancel.Title")},
        ...mapGetters({
            isExpired: 'basket/isExpired',
            happensOn: 'basket/happensOn',
            hasCart: 'basket/hasCart',
            cartId : 'basket/getCartId',
        }),  
    },
    methods: {
        async retryPayment() {
            this.validationSpinner = true
            if (this.cartId && !this.isExpired) {
             let response = await surfapiService.retryPayment(this.cartId)
             if(response.data.redirect) window.location.href = response.data.redirect;
            } else {
                 this.startNewCart()
            }
        },
        startNewCart() {
            this.$store.dispatch('basket/reset')
            this.$router.push('/')
        }
    }
}
</script>

<style scoped>
.inline-block{
    display: inline-block !important;
}
</style>