<template>
  <div class="body" :class="{ 'disparitionforPopup': messagesnack }" v-if="products">

    <header class="components">
      <header-component :pageTitle="pageTitle"/>
    </header>

    <div class="text-linkpopup">
        <p class="description-text">{{ $t("Snack.Info.MiniInfo") }}</p>
        <button class="link-knowmore" @click="messagesnack = !messagesnack">{{ $t("Snack.Info.ShowInfos") }}</button>
    </div>


    <section class="snack-dishes">

      <!-- //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\ -->
      <!-- MEALS -->
      <p class="text">{{ $t("Snack.Snack?") }}</p>
      <div class="carousel-snack">
        <div v-for="(snack, index) of snacks" :key="snack.id" 
          class="carousel-snack--choice snack" :class="{selected: index === 0}"
          @click="selectSnack($event, snack)"
        >
          <div class="carousel-snack--choice__icon" v-show="snack.allergens.length" @click="console.log(snack.allergens)">
            <span class="icon icon-gluten" v-show="snack.allergens.includes('Gluten')"></span>
            <span class="icon icon-hazelnut" v-show="snack.allergens.includes('Lait')"></span>
            <span class="icon icon-milk" v-show="snack.allergens.includes('Fruits à coque')"></span>
            <i class="fa-solid fa-fish icon" v-show="snack.allergens.includes('Poisson')"></i>
          </div>

          <img :src="getImgUrl(`img-snack/${snack.name}.png`)" class="salad-choice" alt="">
          <div class="carousel-snack--choice__text">{{ getMealName(snack.name) }}</div>
        </div>
      </div>

      <div class="snack-description" v-show="finalMenu.snack">
          <div class="snack-description--composition">
              <div class="snack-description--composition__description flex1">
                  <div class="description-title">{{ $t("Snack.Ingrédients") }}</div>
                  <p class="description-text" v-for="ingredient in finalMenu.snack.ingredients" :key="ingredient.id">-{{ $t("Ingrédients."+ingredient) }}</p>
              </div>

              <div class="snack-description--allergene flex5" v-show='finalMenu.snack'>
                <div class="description-title">{{ $t("Snack.Allergènes") }}</div>
                <p class="description-text" v-for="allergene in finalMenu.snack.allergens" :key="allergene.id">-{{ $t("Allergènes."+allergene) }}</p>
            </div>
          </div>
      </div>
    </section>


    <!-- //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\ -->
    <!-- BOISSON -->
    <section class="snack-drinks need-mT2">
      <p class="text">{{ $t("Snack.Boisson?") }}</p>
      <div class="carousel-snack">
        <div v-for="(drink, index) of drinks" :key="drink.id" 
          class="carousel-snack--choice drink" :class="{selected: index === 0}"
          @click="selectDrink($event, drink)"
        >
          <img src="@/assets/img-snack/surfcenter_ipa.png" class="salad-choice" alt="" v-if="drink.id == '19' ">
          <img src="@/assets/img-snack/cans.png" class="salad-choice" alt="" v-else>
          <p class="carousel-snack--choice__text">{{ getDrinkName(drink.name) }}</p>
          <span v-if="drink.markUp" class="markup-pastille">+{{drink.markUp}}€</span>
        </div>
      </div>
    </section>


    <!-- //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\ -->
    <!-- DESSERT -->
    <section class="snack-desserts need-mT2 need-mB2">
      <p class="text">{{ $t("Snack.Dessert?") }}</p>
      <p class="italic text-center color-orange">{{ $t("Snack.Dessert.Info")}}</p>
      <div class="carousel-snack">
        <div v-for="(desert, index) of deserts" :key="desert.id" 
          class="carousel-snack--choice desert" :class="{selected: index === 0}"
          @click="selectDesert($event, desert)"
        >
          <img :src="getImgUrl(`img-snack/${desert.name}.png`)" class="salad-choice" alt="">
          <div class="carousel-snack--choice__text">{{ getDesertName(desert.name) }}</div>
          <span v-if="desert.markUp">+{{desert.markUp}}€</span>
        </div>
      </div>
    </section>

    <button class="add-basket bg-linear" @click="addToCart()">
          <div class="add-basket--text">{{ $t("Snack.AjouterPanier") }}</div>
        <div class="add-basket--price">{{totalPrice}} € <span>{{ $t("Mot.TTC") }}</span></div>
    </button>
  </div>


  <!-- //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\ -->
  <!-- POPUP INFO : LES SNACKS SONT TRÈS TRÈS FRAIS -->

  <!-- Bg derrière pour le border-radius du Pop-up -->
  <div class="bg-body" :class="{ 'apparitionforPopup': popupSnack }"></div> 

  <div class="body-popup" :class="{ 'messageSnack': messagesnack }">
    <div class="text">{{ $t("Snack.Info.Popup.Header") }}</div>

    <div class="popup-logo">
      <img src="@/assets/Logo-seul.svg" class="popup-logo--imagesnack" alt="logo">
      <p class="popup-logo--title">
        {{ $t("Snack.Info.Popup.Title1") }}
        <span>{{ $t("Snack.Info.Popup.Title2") }}</span>
      </p>
    </div>
    
    <div class="popup-text">
      <p class="text">
        {{ $t("Snack.Info.Popup.Content1") }}
      </p>
      <p class="description-text">
        {{ $t("Snack.Info.Popup.Content2") }}
      </p>
      <p class="description-text">
        {{ $t("Snack.Info.Popup.Content3") }}
      </p>
    </div>

    <section class="pagination popup">
      <button class="pagination-button--return" @click="messagesnack = !messagesnack">
        <span class="icon icon-arrow-left1"></span>
        <div class="pagination-button--return__text">{{ $t("Mot.Retour") }}</div>
      </button>
    </section>
  </div>


  <!-- //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\ -->
  <!-- PAGINATION -->
  <section class="pagination">
    <go-back returnLink="/basket" :returnTitle="retour" />
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import HeaderComponent from "@/components/elements/HeaderComponent.vue";
import GoBack from "@/components/elements/GoBack.vue";
import SurfApi from '@/services/surfapi.service';
import dateService from "@/services/date.service";

export default {
  name: "SnackSaladView",
  components: {
    HeaderComponent,
    GoBack,
  },

  data() {
    return {
      ds: dateService,

      //Popup
      messagesnack: false,
      recapsnack:false,
      
      // Données
      date: this.$store.getters['navigation/date'],
      products: [],

      //Menu sélectionné : salade, boisson, dessert
      finalMenu : {
        menu: false,
        snack : false,
        drink : false,
        desert : false,
      }
    }
  },

  computed: {
    ...mapGetters({
      isExpired: 'basket/isExpired',
      happensOn: 'basket/happensOn',
      hasCart: 'basket/hasCart',
      cartId : 'basket/getCartId',
      cart : 'basket/getCart'
    }),

    // trad
    pageTitle(){
      return this.$t("Snack.Title")
    },
    retour(){
      return this.$t("Mot.Retour")
    },

    // affichage
    snacks(){
      return this.products.filter((product) => product.productTypeId == 1)
    },
    drinks(){
      return this.products.filter((product) => product.productTypeId == 3)
    },
    deserts(){
      return this.products.filter((product) => product.productTypeId == 2)
    },

    totalPrice(){
      return (this.finalMenu.menu.price || 0) + (this.finalMenu.drink.markUp || 0) + (this.finalMenu.desert.markUp || 0)
    },

    // popup
    popupSnack() {
      return this.messagesnack || this.recapsnack
    },
  },

  beforeMount(){
    if(this.checkUrl()){
      this.getData()
    }
  },

  methods: {

   /**
    *  ----------------
    *  Gestion de l'URL
    *  ----------------
    * params = id du menu ( 1 = Sandwichs || 2 = Salades)
    * 
    * On check les paramètres de la route avant de charger les données.
    * Les paramètres sont gérés et envoyés par les router-links de SnackMenuView.vue et BasketView.vue
    * 
    * Si l'utilisateur tape une url lui même (ex: snack/Flan), il faut le rediriger vers une 404
    */

    checkUrl(){
      if(this.$route.params.type != 1 && this.$route.params.type != 2){
        this.$router.push('/')
        return false
      }
      return true
    },

  /* //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\ */

   /**
    *  -----------
    *  Data Config
    *  -----------
    */

   async getData(){
     
     if(this.isExpired) this.$router.push('/')
      else {
        // invalid date bug tracker
        if(this.ds.format(this.happensOn,"YYYY-MM-DD") === "Invalid Date"){
          console.error("Bug Tracker : Invalid Date in SnackView")
          console.info("Cart : ", this.cart)
        }

        const menus = await SurfApi.getMenus(this.ds.format(this.happensOn,"YYYY-MM-DD"))

        const targetMenu = menus.data.find((menu) => menu.id == this.$route.params.type)

        const products = await SurfApi.getProducts(targetMenu.id)

        // adapte les ingrédients & allergènes
        this.products = products.data.map((p) => {
          p.ingredients = this.setIngredients(p.ingredients)
          p.allergens = this.setAllergens(p.allergens)
          return p
        })

        this.finalMenu = {
          menu: targetMenu,
          snack: this.snacks[0],
          drink: this.drinks[0],
          desert: this.deserts[0]
        }
      }
    },

    setIngredients(ing){
      if(ing){
        return ing
          .replaceAll("\n", " ")
          .split("-")
          .map((el) => {
            let el2 = el.trim().toLowerCase()
            return el2.charAt(0).toUpperCase() + el2.slice(1)
          })
          .filter((el) => el !== "")
      }
      else return []
    },

    setAllergens(all){
      if(all){
        return all
          .split("\n")
          .map((el) => {
            let el2 = el.trim().toLowerCase()
            return el2.charAt(0).toUpperCase() + el2.slice(1)
          })
          .filter((el) => el !== "")
      }
      else return []
    },
   
   /* //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\ */

   /**
    *  ------
    *    UI
    *  ------
    */
   
    getMealName(name){
      return this.$t(`Menus.${this.finalMenu.menu.name}.Meal.${name}`)
    },
    getDrinkName(name){
      return this.$t(`Menus.Boisson.${name}`)
    },
    getDesertName(name){
      return this.$t(`Menus.Dessert.${name}`)
    },
    getImgUrl(imageName) { 
      return require(`@/assets/${imageName.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().split(' ').join('_')}`);
    },

  /* //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\ */

   /**
    *  -----------------
    *  Selection du menu
    *  -----------------
    */

    selectItem(e, cssClass){
      document.querySelectorAll(cssClass).forEach((el) => {
        el.classList.remove('selected')
      })
      e.target.closest('.carousel-snack--choice').classList.add('selected')
    },

    // Salade
    selectSnack(e, snack){      
      this.finalMenu.snack = snack
      this.selectItem(e, '.snack')
    },

    // Boisson
    selectDrink(e, drink){
      this.finalMenu.drink = drink
      this.selectItem(e, '.drink')
    },

    // Dessert
    selectDesert(e, desert){
      this.finalMenu.desert = desert
      this.selectItem(e, '.desert')
    },

  /* //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\ */

   /**
    *  --------
    *   Panier
    *  --------
    */

    async addToCart(){
      // Données à envoyer
      const menu = {
        menuId: this.finalMenu.menu.id,
        mealProductId: this.finalMenu.snack.id,
        drinkProductId: this.finalMenu.drink.id,
        dessertProductId: this.finalMenu.desert.id,
      }
      
      // Mise à jour de la commande et du panier
      if(!this.isExpired){
        await SurfApi.addMenu(this.cartId, menu)
        this.$router.push("/basket")
      }
    }
  }
}
</script>

<style scoped>
.carousel-snack--choice__icon{
  position: absolute;
  top: -1em;
  right: 0;
  z-index: 1;
  width: 100%;
  justify-content: center;
  display: flex;
  bottom: unset;
  left: 0;
  transform: unset;
}
.carousel-snack--choice__icon .icon {
  border: 2px solid orange;
}
</style>