<template>
  <header class="header-component">
    
    <div class="logo">
      <img src="@/assets/Logo-seul.svg" class="header-logo" alt="logo" />
    </div>

    <h1 class="title1" >{{pageTitle}}</h1>

    <div class="header-component--menu">
      <button class="burger-menu" @click="showMenu = !showMenu" :class="{ 'animation-burger': showMenu }">
        <span class="hamburger--item"></span>
        <span class="hamburger--item"></span>
        <span class="hamburger--item"></span>
      </button>
    </div>

    <section class="menu--container" :class="{ 'sub-menu': showMenu }">
      <div class="primary-menu">
        <img src="@/assets/Logo-seul.svg" class="primary-menu--logo" alt="logo">
        <p class="primary-menu--title">Surf Center <span>{{ $t('Header.Location') }}</span></p>

        <div class="menu-container-items">

          <local-switcher />

          <a href="https://surfcenter.fr/" class="primary-menu--boat bg-linear"><span class="text">{{ $t('Header.RetourSite') }}</span></a>

          <button class="primary-menu--boat bg-linear" @click="toCalendar()">
            <span class="text">{{ $t('Header.ResaJour') }}</span>
          </button >

          <button class="primary-menu--boat bg-linear" @click="toTomorrow()">
            <span class="text">{{ $t('Header.ResaLendemain') }}</span>
          </button >
          
          <button class="primary-menu--boat bg-linear" @click="toBasket()">
            <span class="text">{{ $t('Header.Panier') }}</span>
          </button >

        </div>
      </div>
      <div class="social-network">
        <a href="https://www.facebook.com/surfcenterlessallessurverdon/" target="_blank" class="icon icon-facebook"></a>
        <a href="https://www.instagram.com/surfcenter_verdon/?hl=fr" target="_blank" class="icon icon-instagram"></a>
        <a :href="`tel:${cellphone}`" class="icon icon-phone"></a>
        <a :href="`mailto:${email}`" class="icon icon-mail"></a>
        <span class="version">v{{version}}</span>
      </div>
    </section>
  </header>
</template>

<script>
import { mapGetters } from 'vuex'
import LocalSwitcher from './LocalSwitcher.vue'

export default {
    components: {
      LocalSwitcher
    },
    props: {
        pageTitle: String,
    },
    data() {
        return {
            cellphone: window.VUE_APP_PHONE,
            email: window.VUE_APP_EMAIL,
            showMenu: false,
        };
    },
    computed: {
        ...mapGetters({
            version: "navigation/version"
        }),
    },
    beforeMount() {
        console.log("current version: ", this.version);
    },
    methods: {
        toCalendar() {
            if (this.$route.path.includes("calendar")) {
                this.showMenu = false;
            }
            else {
                this.$router.push("/calendar");
            }
        },
        toTomorrow() {
            if (this.$route.path.includes("tomorrow")) {
                this.showMenu = false;
            }
            else {
                this.$router.push("/add-boat/tomorrow");
            }
        },
        toBasket() {
            if (this.$route.path.includes("basket")) {
                this.showMenu = false;
            }
            else {
                this.$router.push("/basket");
            }
        },
    },
}
</script>

<style>
  .version {
    position: absolute;
    bottom: 5%;
    right: 1%;
    color: rgb(222, 222, 222);
    font-size: .5em;
  }
</style>