<template>
  <div class="body body-first-page">
    <header class="components">
      <div class="header-component">
        <div class="logo">
          <img src="@/assets/Logo-seul.svg" class="header-logo" alt="logo" />
        </div>
      </div>
    </header>

    <p class="text"> {{ $t('Header.Bienvenue') }}</p>
    
    <main class="home-btns">
      <locale-switcher/>
      <a href="https://surfcenter.fr" class="primary-menu--boat bg-linear"><span class="text">{{ $t('Header.RetourSite') }}</span></a>
      <router-link :to="'/calendar'" class="primary-menu--boat bg-linear">
        <span class="text">{{ $t('Header.ResaJour') }}</span>
      </router-link >
      <router-link :to="'/add-boat/tomorrow'" class="primary-menu--boat bg-linear">
        <span class="text">{{ $t('Header.ResaLendemain') }}</span>
      </router-link >
      <router-link :to="'/basket'" class="primary-menu--boat bg-linear">
        <span class="text">{{ $t('Header.Panier') }}</span>
      </router-link >
    </main>

    <footer class="social-network">
      <a href="https://www.facebook.com/surfcenterlessallessurverdon/" target="_blank" class="icon icon-facebook"></a>
      <a href="https://www.instagram.com/surfcenter_verdon/?hl=fr" target="_blank" class="icon icon-instagram"></a>
      <a :href="`tel:${cellphone}`" class="icon icon-phone"></a>
      <a :href="`mailto:${email}`" class="icon icon-mail"></a>
    </footer>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import dateService from '@/services/date.service'
import LocaleSwitcher from '@/components/elements/LocalSwitcher.vue'

export default {

  components: {
    LocaleSwitcher
  },

  data(){
    return {
      // hasCart: StorageService.hasOrderStored() && !SurfApi.cartHasExpired()
      cellphone: window.VUE_APP_PHONE,
      email: window.VUE_APP_EMAIL,
      dropDown: false
    }
  },
  computed: {
    ...mapGetters({
      isExpired: 'basket/isExpired',
      happensOn: 'basket/happensOn',
      hasCart: 'basket/hasCart',
      locale: 'navigation/locale'
    }),

    cartDisplayDate() {
      return this.hasCart ? `Mon panier pour le ${dateService.toResaDetails(this.happensOn)}` : 'Mon panier'
    },

  },

}
</script>


<style scoped>
.header-component {
  position: relative;
}

</style>