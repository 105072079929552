/**
     * -------
     *  CART (ORDER) Storage
     * -------
 */
  
class StorageService {
    /**
     * Define LocalStorageKeys
     */
    localStorageCartKey = `${window.VUE_STORAGE_KEY_PREFIX}-surfcenter-cart`;
   
    // save
    saveCart(order) {
        localStorage.setItem(this.localStorageCartKey, JSON.stringify(order))
    }

    // get
    getCart() {
        var cart = localStorage.getItem(this.localStorageCartKey)
        return cart ? JSON.parse(cart) : null
    }
    // delete
    removeCart(){
        localStorage.removeItem(this.localStorageDateKey)
    }
}

export default new StorageService()