export default {
  "1)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["___________________ GLOBALS ___________________"])},
  "locale": {
    "full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["français"])}
  },
  "2)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["____________________ VIEWS ____________________"])},
  "App": {
    "MajDispo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une mise à jour est disponible"])},
    "MettreAJour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour"])}
  },
  "Calendar": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choix du bateau & du jour"])},
    "ChoixJour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez un jour"])},
    "Warning1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez déjà un panier en cours pour le"])},
    "Warning2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Si vous ajoutez une réservation pour une autre date, le panier sera réinitialisé."])}
  },
  "AddBoat": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réserver un bateau"])},
    "ChoixType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez un type de"])},
    "NoDispoDemain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désolés nous n'avons plus de créneau disponible demain"])},
    "AutreRésa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réserver pour un autre jour"])},
    "TrouvePasBonheur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne trouvez pas votre bonheur ?"])},
    "QuePourDemain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ici, il ne s'agit que des disponibilités pour demain"])},
    "PeutRésaAutreJour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez réserver pour un autre jour"])},
    "Error1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une réservation pour le"])},
    "Error2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["est enregistrée dans votre panier. Si vous ajoutez une réservation ici, votre panier sera réinitialisé."])}
  },
  "Snack": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Composez votre menu"])},
    "Info": {
      "MiniInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous nos produits sont frais et emballés avec soin !"])},
      "ShowInfos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En savoir plus"])},
      "Popup": {
        "Header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A propos de nos snacks"])},
        "Title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surf Center"])},
        "Title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
        "Content1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simplifiez-vous la vie !"])},
        "Content2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez commander votre pique-nique directement en ligne. Chaque matin nous vous préparons des paniers repas frais et savoureux, pour apprécier au mieux votre journée!"])},
        "Content3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au menu : sandwich, salade, wrap, soda et dessert maison. Vous aurez accès à une large partie de notre carte. Votre commande est préparée avant votre départ dans un sac isotherme accompagné d’un pain de glace pour garder toute sa fraîcheur."])}
      }
    },
    "Snack?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quel snack ?"])},
    "Ingrédients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrédients"])},
    "Allergènes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allergènes"])},
    "Boisson?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelle boisson ?"])},
    "Dessert?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quel dessert ?"])},
    "Dessert": {
      "Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous nos desserts sont faits maison"])}
    },
    "AjouterPanier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter au panier"])}
  },
  "Basket": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre panier"])},
    "PanierExpiré": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre panier a expiré :("])},
    "PanierVide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre panier est vide ! Cliquez sur le bouton pour réserver :)"])},
    "CommencerUneResa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencer une réservation"])},
    "Départ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Départ"])},
    "Retour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
    "ViderPanier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vider le panier"])},
    "ExpireDans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre panier expire dans "])},
    "CodePromo?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez un code promo ?"])},
    "VotreCodePromo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre code promo"])},
    "PromoErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désolé, ce code promo n'est pas valide"])},
    "Reduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réduction"])},
    "Montant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant à payer"])},
    "Boat?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous ajouter un autre bateau ?"])},
    "Snacks?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous ajouter un snack ?"])},
    "AjouterBateau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un bateau"])},
    "NouveauCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir une réservation"])}
  },
  "Succes": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "Merci": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surf Center vous remercie pour votre commande !"])},
    "MailInfos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La suite des informations vous est transférée par mail."])},
    "SiQuestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour toute question, n'hésitez pas à consulter"])},
    "NotreFAQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["notre FAQ"])},
    "ABientot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À très vite !"])},
    "AutreRésa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faire une autre réservation"])}
  },
  "Cancel": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "Dommage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops !"])},
    "PaimentError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le paiement n'est pas passé :'("])},
    "SiQuestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour toute question, n'hésitez pas à consulter"])},
    "NotreFAQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["notre FAQ"])},
    "Reessayer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réessayer"])},
    "AutreRésa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faire une autre réservation"])}
  },
  "Formulaire": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos informations"])},
    "Placeholder1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Écrivez ici"])},
    "Placeholder2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facultatif - max 255 caractères"])},
    "Nom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "Prénom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
    "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "IndexTéléphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Index téléphonique"])},
    "ChoixListe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez dans la liste"])},
    "Téléphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])},
    "Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laissez nous un message"])},
    "ErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez remplir tous les champs avec une *"])},
    "ExpireDans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre panier expire dans "])},
    "CommenceRésa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencer une réservation"])},
    "PanierExpiré": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre panier a expiré, merci de recommencer votre réservation. Pour rappel, vous avez 15 minutes pour finaliser votre réservation"])},
    "CaracteresMax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["caractères restants"])}
  },
  "3)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["___________________ COMPOSANTS ___________________"])},
  "Header": {
    "Location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOCATION"])},
    "Bienvenue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue sur le site de réservation de Surf Center !"])},
    "ResaJour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réserver un bateau"])},
    "ResaLendemain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réserver pour le lendemain"])},
    "Panier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon panier"])},
    "RetourSite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour sur le site"])},
    "Langue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue"])}
  },
  "ResaDetail": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de votre commande"])},
    "ChoixCréneau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez un créneau"])},
    "StockWarning": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus que"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["disponible"])},
      "pluriel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["s"])}
    },
    "Quantité": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'embarcation : "])},
    "CreneauPasSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez choisir un créneau horaire"])}
  },
  "WarningPopup": {
    "Title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surf Center"])},
    "Title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
    "Important1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Important"])},
    "Important2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à savoir"])},
    "Warning1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous vous apprêtez à procéder au paiement de votre réservation."])},
    "Warning2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assurez-vous bien de n'avoir commis aucune erreur."])},
    "Warning3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez noter qu'en raison d'une forte affluence touristique aucun changement dans la date ou le type de réservation ne sera possible et qu'aucun remboursement ne sera effectué mise à part en cas de météo nous obligeant à laisser nos embarcations à terre (vent violent, orage, ...)."])}
  },
  "4)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["____________________ COMMONS ____________________"])},
  "Boats": {
    "undefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "Pédalos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pédalo toboggan 5 personnes"])},
    "Pédalo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pédalo toboggan 5 personnes"])},
    "Paddle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paddle 1 place"])},
    "Bateaux Électriques": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bateau Électrique 6 personnes"])},
    "Canoë": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canoë"])},
    "Canoë 1 place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canoë 1 place"])},
    "Canoë 2 places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canoë 2 places"])},
    "Canoë 3 places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canoë 3 places"])},
    "Variants": {
      "1 place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 place"])},
      "2 places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 places"])},
      "3 places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 places"])}
    },
    "1 place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 place"])},
    "2 places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 places"])},
    "3 places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 places"])}
  },
  "Menus": {
    "Placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Écrivez ici"])},
    "Salade": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salade"])},
      "Meal": {
        "Salade Pêcheuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salade Pêcheuse"])},
        "Salade Poulette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salade Poulette"])},
        "Salade Sicilienne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salade Sicilienne"])},
        "Végé Bowl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Végé Bowl"])}
      }
    },
    "Sandwich": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sandwich"])},
      "Meal": {
        "Chicken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chicken"])},
        "Wrap Poséidon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrap Poséidon"])},
        "Italien": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italien"])},
        "Original": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Original"])},
        "Wrap Végé": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrap Végé"])}
      }
    },
    "Boisson": {
      "Coca": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coca"])},
      "Coca Zéro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coca Zéro"])},
      "Orangina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orangina"])},
      "Stella Artois 33cl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stella Artois 33cl"])},
      "Leffe 33cl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leffe 33cl"])},
      "SurfCenter IPA 33cl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SurfCenter IPA 33cl"])},
      "Eau plate 50cl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eau plate 50cl"])},
      "Perrier 33cl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perrier 33cl"])},
      "Limonade 25cl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limonade 25cl"])}
    },
    "Dessert": {
      "Brownie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brownie"])},
      "Cookie Chocolat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookie Chocolat"])},
      "Financier Myrtilles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financier Myrtilles"])}
    }
  },
  "Ingrédients": {
    "Saumon fumé": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saumon fumé"])},
    "Concombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concombre"])},
    "Tomate cerise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tomate cerise"])},
    "Oignon rouge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oignon rouge"])},
    "Fusilli": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fusilli"])},
    "Sauce blanche": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauce blanche"])},
    "Poulet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poulet"])},
    "Parmesan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parmesan"])},
    "Tomates cerises": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tomates cerises"])},
    "Oignons frits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oignons frits"])},
    "Jambon cru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jambon cru"])},
    "Fusilli au pesto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fusilli au pesto"])},
    "Tomates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tomates"])},
    "Quinoa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quinoa"])},
    "Avocat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avocat"])},
    "Carotte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carotte"])},
    "Féta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Féta"])},
    "Cacahuète": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cacahuète"])},
    "Olives noires": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olives noires"])},
    "Tomate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tomate"])},
    "Salade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salade"])},
    "Saumon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saumon"])},
    "Fromage à tartiner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fromage à tartiner"])},
    "Chèvre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chèvre"])},
    "Pesto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesto"])},
    "Tomate confite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tomate confite"])},
    "Jambon blanc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jambon blanc"])},
    "Emmental": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emmental"])},
    "Noix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noix"])}
  },
  "Allergènes": {
    "Gluten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gluten"])},
    "Lait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lait"])},
    "Poisson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poisson"])},
    "Fruits à coque": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fruits à coque"])}
  },
  "5)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["_________________ MOTS ISOLÉS _________________"])},
  "Mot": {
    "Retour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
    "Valider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider"])},
    "Continuer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
    "Menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])},
    "Prix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix"])},
    "Produit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produit(s)"])},
    "Quantité": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité"])},
    "Minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minutes"])},
    "Appliquer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appliquer"])},
    "Payer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payer"])},
    "Total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "TTC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTC"])},
    "Aucun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun"])}
  },
  "6)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["_________________ MESSAGES DIVERS _________________"])},
  "Messages": {
    "SiteWeb": {
      "Texte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous souhaitez en savoir plus sur nos supports nautiques ?"])},
      "Lien": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visitez notre site de présentation"])}
    }
  }
}