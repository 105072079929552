/* eslint-disable no-console */

import { register } from "register-service-worker"

// if (process.env.NODE_ENV === "production") {
    register(`${process.env.BASE_URL}service-worker.js`, {
        ready() {

        },
        registered() {
          console.log("Service worker has been registered.");
          self.addEventListener('message', (event) => {
            if (event.data === 'SKIP_WAITING') {
                  console.log('receive message skipwaiting', event)
                  self.skipWaiting();
              }
          });
        },
        cached() {
          console.log("Content has been cached for offline use.");
        },
        updatefound() {
          console.log("New content is downloading.");
        },
        updated(registration) {
          console.log("Une Nouvelle Version de l'appli est disponible : rafraichir ?");
          document.dispatchEvent(
            new CustomEvent('surf-updated', { detail: registration })
          )
        },
        offline() {
          console.log(
            "No internet connection found. App is running in offline mode."
          );
        },
        error(error) {
          console.error("Error during service worker registration:", error);
        },
    })

// }

