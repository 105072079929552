import dayjs from 'dayjs'
import store from '@/store'

// dayjs locales
require('dayjs/locale/fr')
require('dayjs/locale/de')

// dayjs plugins
const isTomorrow = require('dayjs/plugin/isTomorrow')
dayjs.extend(isTomorrow)
const isToday = require('dayjs/plugin/isToday')
dayjs.extend(isToday)

class DateService {

    localized(date) {
        return dayjs(date).locale(store.state.navigation.locale)
    }

    // Le jour où refacto se servir uniquement de ça partout -> la fonction ultime !!!
    format(date, format) {
        return dayjs(date).locale(store.state.navigation.locale).format(format)
    }

    getToday() {
        return dayjs()
    }

    getTomorrow() {
        return dayjs().add(1, 'day')
    }

    getWeekDays() {
        const weekDays = []
        for (let i = 1; i < 7; i++) {
            weekDays.push(dayjs().day(i))
        }
        weekDays.push(dayjs().day(0))
        return weekDays
    }

    /* //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\ */

    /**
     * ----------
     * UI DISPLAY
     * ----------
     */

    displayWeekDay(day) {
        return this.localized(day).format("DD")
    }
    displayDayName(day) {
        return this.localized(day).format("dd")
    }
    displayMonth(day) {
        return this.localized(day).format("MMMM")
    }
    displayYear(day) {
        return this.localized(day).format("YYYY")
    }

    adaptScheduleTime(scheduleTime) {
        return scheduleTime.replace(':', 'h').split(':')[0]
    }

    formatDate(aDateToFormat, formatToUse) {
        return dayjs(aDateToFormat).format(formatToUse)
    }
    formatForApi(cartDate) {
        return this.formatDate(cartDate, "YYYY-MM-DD")
    }

    dayInterval(date) {
        return ({
            beginDate: date.format("YYYY-MM-DD"),
            endDate: date.format("YYYY-MM-DD")
        })
    }

    tomorrowInterval() {
        return ({
            beginDate: this.tomorrow.format("YYYY-MM-DD"),
            endDate: this.tomorrow.add(1, 'day').format("YYYY-MM-DD")
        })
    }

    // Pour la transformation au format dayjs des dates envoyées par l'API dans CalendarView
    formatDateFromApi(apiDataArray) {
        return apiDataArray.map((data) => {
            return ({
                ...data,
                date: dayjs(data.date.slice(0, 10))
            })
        })
    }



    isSameDate(date1, date2) {
        return dayjs(date1).format("YYYY MM DD") == dayjs(date2).format('YYYY MM DD')
    }


    checkTomorrow(date) {
        return dayjs(date).isTomorrow()
    }
    checkToday(date) {
        return dayjs(date).isToday()
    }


    isPastDay(date) {
        return dayjs(date).isBefore(dayjs())
    }

    storedDate() {
        const date = JSON.parse(localStorage.getItem('date'))
        return dayjs(date.slice(0, 10))
    }





    // old 

    convert(date) {
        return dayjs(date).format("YYYY-MM-DD")
    }

    toCalendarDay(date) {
        return dayjs(date).format("DD")
    }
    toResaDetails(date) {
        return dayjs(date).format("DD MMMM")
    }
    toDayjsFormat(date) {
        return dayjs(date)
    }

    // date format : YYYY-MM-DD
    newDay(date) {
        console.log(dayjs(date))
        return dayjs(date)
    }
}

export default new DateService()