import StorageService from '@/services/storage.service'
import dayjs from 'dayjs';

const cart = StorageService.getCart();

const initialState = cart ?
    { currentCart: cart, hasCart: true }
    : { currentCart: { orderId: null, happensOn: null, expiresOn: null, status:null}, hasCart : false }


export const basket = {
    namespaced: true,
    state: initialState,
    getters: {
        getCartId(state){
            return state.currentCart.orderId
        },
        isExpired(state) {
            const expirationTimeMinutes = dayjs().diff(state.currentCart.expiresOn, 'minutes')
            
            if (expirationTimeMinutes < 0) {
                console.log("Cart will expire in " + (expirationTimeMinutes * -1) + ' minutes')
                return false
            } 
            console.log("Cart expired  " + expirationTimeMinutes + 'minutes ago ')
            return true
            
        },
        happensOn(state) {
            return state.currentCart.happensOn
        },
        hasCart(state) {
            return state.hasCart
        },
        getStatus(state) {
            return state.currentCart.status
        },
        getExpiration(state) {
            return state.currentCart.expiresOn
        },
        getCart(state){
            return state.currentCart
        }
    },
    actions: {
        update({ commit }, payload){
            commit('update', payload)
        },

        reset({ commit }) {
            commit('reset')
        },
        updateExpiration({ commit }, payload) {
            commit('updateExpiration', payload)
        }
    },
    mutations: {
        update(state, payload) {
            state.currentCart = payload
            state.hasCart = true
            StorageService.saveCart(payload)
        },
        updateExpiration(state, payload) {
          state.currentCart.expiresOn = payload
        },
        
        reset(state){
            state.hasCart = false
            state.currentCart = { orderId: null, happensOn: null, expiresOn: null, status: null}
            StorageService.removeCart()
        },
    }
};
