
<template>
    <div class="body-popup popupResaBoat">

        <p class="text">{{ $t("ResaDetail.Title") }}</p>

        <!-- //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\ -->
        <!-- RECAP BATEAU TYPE -->
        <section class="recap-items">
            <div class="recap-top-div">
                <div class="bg-grey column flex1">
                    <div class="recap-items no-mT">
                        <div class="recap-items--icon bg-linear">
                            <span :class="'icon icon-boat' + boat.boatsTypeId "></span>
                        </div>
                    </div>
                    <p class="recap-items--text text-center" v-if="boat.parentName">{{ $t("Boats."+boat.parentName) }} {{ $t("Boats."+boat.name) }}</p>
                    <p class="recap-items--text text-center" v-else>{{ $t("Boats."+boat.name) }}</p>
                </div>

                <div class="bg-grey column flex1">
                    <div class="recap-items no-mT">
                        <div class="recap-items--icon bg-linear"><span class="icon icon-calendar"></span></div>
                    </div>
                    <div class="recap-items--text capitalize text-center" v-if="getDate">{{ds.format(getDate, "dddd DD")}}<span>{{ds.format(getDate, "MMMM YYYY")}}</span></div>
                </div>
            </div>
        </section>

        <!-- //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\ -->
        <!-- SELECT RESERVATION TIME -->
        <p class="text">{{ $t("ResaDetail.ChoixCréneau")}} :</p>
        <section class="bg-grey column available-hours">
            
            
            <div class="container-calendar-hours-price">
                <div class="calendar-hours-price" v-for="av of getAvailabilites" :key="av.id" @click="selectTimeSlot($event, av)">
                    <button class="schedule" :class="av.stock != 0 ? (av.stock > 2 ? 'available' : 'lessavailable') : 'notavailable'">
                        {{ adaptTime(av.hourBegin) }} - {{ adaptTime(av.hourEnd) }}
                        <span class="index-price" :class="av.stock != 0 ? (av.stock > 2 ? 'available' : 'lessavailable') : 'notavailable'">{{av.price}} €</span>
                    </button>
                </div>
            </div>
        </section>

        <!-- //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\ -->
        <!-- SELECT QUANTITY -->
        <section class="column align-center">
            <p class="text">{{ $t("ResaDetail.Quantité") }}</p>

            <div class="quantity-choice">
                <button class="quantity-choice--btn bg-linear" @click="removeBoat()" :disabled="!selectedTimeSlot || hasReadMinimum">
                    <span>-</span>
                </button>
                <span class="quantity">{{ booking.quantity }}</span>

                <button class="quantity-choice--btn bg-linear" @click="addBoat()" :disabled="!selectedTimeSlot || hasReachedMaximum">
                    <span>+</span>
                </button>
            </div>
            <div class="availability-container" v-show="boatStock<3 && boatStock>0">
                <p class="text" v-if="boat.parentName">{{ $t("ResaDetail.StockWarning.1") }} {{boatStock}} {{ $t("Boats."+boat.parentName) }} {{ $t("Boats."+boat.name) }} {{ $t("ResaDetail.StockWarning.2") }}<span v-show="boatStock>1">{{ $t("ResaDetail.StockWarning.pluriel") }}</span></p>
                <p class="text" v-else>{{ $t("ResaDetail.StockWarning.1") }} {{boatStock}} {{ $t("Boats."+boat.name) }} {{ $t("ResaDetail.StockWarning.2") }}<span v-show="boatStock>1">{{ $t("ResaDetail.StockWarning.pluriel") }}</span></p>
            </div>
        </section>

        <!-- //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\ -->
        <!-- TOTAL PRICE -->
        <section class="need-mTB2">
            <p v-show="selectedTimeSlot" class="text-gradient fontsize14 bold">{{ $t("Mot.Total") }} : {{selectedTimeSlot.price * booking.quantity || 0}} € {{ $t("Mot.TTC")}}</p>
            <p v-show="!selectedTimeSlot" class="text-gradient fontsize14 bold"><span>{{ $t("ResaDetail.CreneauPasSelect")}}</span></p>
        </section>

        <!-- //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\ -->
        <!-- PAGINATION -->
        <section class="pagination popup">
            <button  @click="close()" class="pagination-button--return">
                <span class="icon icon-arrow-left1"></span>
                <p class="pagination-button--return__text">{{ $t("Mot.Retour") }}</p>
            </button>

            <button @click="validate()" class="pagination-button--next bg-linear relative" :disabled="!selectedTimeSlot || validationSpinner">
                <span class="pagination-button--next__text">{{ $t("Mot.Valider")}}</span>
                <spinner-component class="absolute-btn-spinner" v-show="validationSpinner"></spinner-component>
            </button>
        </section>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SurfApi from '@/services/surfapi.service'
import dateService from '@/services/date.service'
import SpinnerComponent from '@/components/elements/SpinnerComponent.vue'


export default {
    props: [
        'boat',
    ],

    data(){
        return {
            ds: dateService,
            selectedTimeSlot: false,
            booking: {
                scheduleId: false,
                quantity: 1
            },
            availabilities : [],
            validationSpinner : false
        }
    },

    components: {
        SpinnerComponent
    },

    computed: {
        ...mapGetters({
            isExpired: 'basket/isExpired',
            happensOn: 'basket/happensOn',
            hasCart: 'basket/hasCart',
            cartId : 'basket/getCartId',
            getDate: 'navigation/getDate',
            getAvailabilites: 'navigation/getAvailabilities'
        }),
        hasReachedMaximum() {
            if (this.selectedTimeSlot)
                return (this.booking.quantity == this.selectedTimeSlot.stock)
            return false
        },
        hasReadMinimum() {
             if (this.selectedTimeSlot)
                return (this.booking.quantity == 1)
            return false
        },
        boatStock(){
            return this.selectedTimeSlot.stock
        }
    },
    methods: {

        /**
         * ------
         *   UI
         * ------
         */

        adaptTime(time){
            return time.replace(':', 'h').split(':')[0]
        },

    /* //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\// */

        /**
         * -----------------
         * Sélection détails
         * -----------------
         */

        selectTimeSlot(e, time){
            document.querySelectorAll('.schedule').forEach((el) => {
                el.classList.remove('selected')
            })
            e.target.closest('.schedule').classList.add('selected')
            this.selectedTimeSlot = time
            this.booking.scheduleId = time.scheduleId
            this.booking.quantity = 1
            //console.log(time)
        },

        addBoat() {
            if (this.booking.quantity < this.selectedTimeSlot.stock) this.booking.quantity++
        },

        removeBoat() {
            if (this.booking.quantity > 1) this.booking.quantity--
        },

    /* //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\// */

        /**
         * -------------------
         * Retour & Validation
         * -------------------
         */

        close(){
            this.$emit('close')

            document.querySelectorAll('.schedule').forEach((el) => {
                el.classList.remove('selected')
            })

            this.selectedTimeSlot = false
            this.booking = {
                scheduleId: false,
                quantity: 1
            }

            this.$store.dispatch("navigation/removeAvails");
        },

        async validate() {
            var keepExistingCart = 
                this.hasCart
                && !this.isExpired 
                && dateService.isSameDate(this.getDate, this.happensOn);
            
            if (this.booking.scheduleId) {
                this.validationSpinner = true
                let cart = keepExistingCart ?  await SurfApi.addBoat(this.cartId, this.booking) : await SurfApi.createCart(this.booking)
                this.$store.dispatch('basket/update', cart.data)

                // invalid date bug tracker
                if(this.ds.format(this.happensOn,"YYYY-MM-DD") === "Invalid Date"){
                    console.error("Bug Tracker : Invalid Date in ResaDetailsPopup")
                    console.info("Cart : ", cart)
                }

                this.$router.push("/basket");
            }
        },
    }


}

</script>

