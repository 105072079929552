<template>
    <div class="body" :class="{ 'disparitionforPopup': showDetailsPopup }">

        <header class="components">
            <HeaderComponent :pageTitle="pageTitle"/>
        </header>

        <main class="components">            
            <h1 class="text need-mT4">
                {{ $t("Succes.Merci") }}
            </h1>
            
            <section class="need-mTB2 column row-center" >
                <!--p class="text">{{ $t("Succes.Merci") }}</p-->
                <p class="text inline-block">
                    {{ $t("Succes.MailInfos") }}
                </p>
                <p class="text inline-block">
                    {{ $t("Succes.SiQuestions") }}
                    <a class="common-link" href="https://surfcenter.fr/foire-aux-questions/" target="_blank"> {{ $t("Succes.NotreFAQ") }} </a>
                </p>
                <p class="text center">
                    {{ $t("Succes.ABientot") }}
                </p>
            </section>
            <section class="pagination">
            <button @click="startNewCart()" class="pagination-button--next bg-linear">
                <div class="pagination-button--next__text">{{ $t("Succes.AutreRésa") }}</div>
            </button>
            <!--a class="text" href="https://surfcenter.fr">Retourner sur le site</a-->
            </section>
        </main>
    </div>
</template>

<script>
import HeaderComponent from '@/components/elements/HeaderComponent.vue'
import storageService from '@/services/storage.service'

export default {
    components: {
        HeaderComponent
    },

    computed:{
        pageTitle(){return this.$t("Succes.Title")}
    },

    beforeMount() {
        this.$store.dispatch('basket/reset')
        storageService.removeCart()
    },
    
    methods:{
        startNewCart() {
            this.$router.push('/')
        },
    }
}
</script>

<style scoped>
.inline-block{
    display: inline-block !important;
}
</style>