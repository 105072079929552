import DateService from '@/services/date.service'

export const navigation = {
    namespaced: true,

    state: {
        availabilities: [],
        date: null,
        version: null,

        // Locale par défaut : modifier si détecteur de langue activé ?
        locale: "fr"
    },

    getters: {
        locale(state){
            return state.locale
        },

        version(state){
            return state.version
        },
        
        getAvailabilities(state){
            return state.availabilities
        },

        getDate(state) {
            return state.date
        }
    },

    actions: {
        version({commit}, version){
            commit('version', version)
        },

        changeLocale({ commit }, newLocale) {
            commit('updateLocale', newLocale)
        },

        setTomorrow({ commit }) {
            const date = DateService.getTomorrow()
            commit('setDate', date)
        },

        setAvailabilities({commit}, availabilities){
            commit('setAvails', availabilities)
        },
        
        removeAvails({ commit }) {
            commit('removeAvails')
        },

        setDate({ commit }, newDate) {
            commit('setDate',newDate)
        }
    },
    
    mutations: {
        version(state, version){
            state.version = version
        },

        updateLocale(state, newLocale) {
            state.locale = newLocale
        },

        setDate(state, date){
            state.date = date
        },

        setAvails(state, availabilities){
            state.availabilities = availabilities
        },
        
        removeAvails(state) {
            state.availabilities = []
        }
    }
}