<template>
  <div class="body" :class="{ 'disparitionforPopup': showDetailsPopup }">

    <header class="components">
      <HeaderComponent :pageTitle="pageTitle"/>
    </header>

    <p class="text-warning" v-show="this.hasCart && !ds.isSameDate(this.getDate,this.happensOn) && !this.isExpired">
      {{ errorMessage }}
    </p>

    <!-- Affichage date -->
    <section class="date">
      <div class="date-container center">
        <div class="day" v-if="getDate">{{ ds.format(getDate, "dddd DD") }}</div>
        <div class="month-years" v-if="getDate">{{ds.format(getDate, "MMMM YYYY") }}</div>
      </div>
    </section>

    <main class="boat-container-index">

      <spinner-component v-show="loading"></spinner-component>

      <section v-for="boat in filteredBoatData" :key="boat.index" >
        <button class="boat-container displayF" :id="boat.boatsTypeId" @click="selectBoat($event,boat)">
          <div class="boat-container--left">
            <div class="boat-logo bg-linear">
              <span class="icon" :class="'icon-boat' + boat.boatsTypeId"></span>
            </div>
            <p class="boat-text">{{ getBoatName(boat.name) }}</p>
          </div>
          <div v-if="!boat.children.length" class="index available" :class="getAvailIndex(boat)"></div>
        </button>
      
        <div class="complement-reservation">
          <div v-if="boat.children.length > 0">
            <p class="text">{{ $t('AddBoat.ChoixType') }} {{ getBoatName(boat.name) }}</p>
            <div class="sub-category flex_center">
              <div v-for="variante in getAvailableVariant(boat.children)" :key="variante.id">
                <button class="sub-category--items" :id="variante.boatsTypeId" @click="selectVariante($event,variante, boat.name)">
                  <div class="sub-category--image">
                    <span class="icon" :class="'icon-boat' + variante.boatsTypeId"></span>
                  </div>
                  <p class="sub-category-text">
                    <span>{{ getVariantName(variante.name) }}</span>
                    <span class="index index-small" :class="getAvailIndex(variante)"></span>
                  </p>
                </button>
              </div> 
            </div>
          </div>
        </div>
      </section>


      <aside class="text-info need-mT4">
          <p>{{ $t("Messages.SiteWeb.Texte")}} <a href="https://surfcenter.fr/nos-locations/" target="_blank" class="common-link color-blue italic">{{ $t("Messages.SiteWeb.Lien")}}</a></p>
      </aside>

      <!-- Si pas de disponibilités -->
      <div class="message-indisponibilite" v-show="showNotBoatsAvailable">
          <p>{{ $t('AddBoat.NoDispoDemain') }}</p>
          <router-link :to="'/calendar'" class="btn notavailable">{{ $t('AddBoat.AutreRésa') }}</router-link>
      </div>

      <!-- Pagination -->
      <footer class="message-indisponibilite text-center" v-if="showButtonForAnotherDay">
          <p class="text">{{ $t('AddBoat.TrouvePasBonheur') }}</p>
          <p>{{ $t("AddBoat.QuePourDemain") }}</p>
          <router-link :to="'/calendar'" class="btn bg-blue text-center">{{ $t('AddBoat.PeutRésaAutreJour') }}</router-link>
      </footer>

      

    </main>



    <!-- //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\ -->
    <!-- POPUPS -->

    <!-- Bg derrière pour le border-radius du Pop-up -->
    <div class="bg-body apparitionforPopup" v-show=" showDetailsPopup"></div>

    <resa-details-popup
        v-show="showDetailsPopup"
        :boat="selectedBoat"
        @close="showDetailsPopup = false"
    />

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SurfApi from '@/services/surfapi.service'
import DateService from '@/services/date.service'
import HeaderComponent from "@/components/elements/HeaderComponent.vue"
import ResaDetailsPopup from '@/components/popups/ResaDetailsPopup.vue'
import SpinnerComponent from '@/components/elements/SpinnerComponent.vue'

export default {
  name: "AddBoatView",

  components: {
    HeaderComponent,
    ResaDetailsPopup,
    SpinnerComponent,
  },

  data() {
    return {
      ds: DateService,

      // UI
      showDetailsPopup: false,
      loading: true,

      // data
      boatData: [],
      selectedBoat: false,
    }
  },

  computed: {
    ...mapGetters({
      isExpired: 'basket/isExpired',
      happensOn: 'basket/happensOn',
      hasCart: 'basket/hasCart',
      getDate: 'navigation/getDate'
    }),

    pageTitle() { return this.$t("AddBoat.Title")},

    errorMessage () { return  `${this.$t("AddBoat.Error1")} ${this.ds.format(this.happensOn, "dddd DD MMMM YYYY")} ${ this.$t('AddBoat.Error2') }` },

    showNotBoatsAvailable () { return  !this.loading && this.boatData.length == 0},

    showButtonForAnotherDay() { return !this.loading && this.boatData.length > 0 },

    filteredBoatData() {
      return this.boatData.filter(
        (boat) => {
          if (boat.children.length) {
            return boat.children.filter((children) => (children.availabilities.length > 0) ).length > 0
          }
          else return boat.availabilities.length > 0
        }
      )
    }
  },

  beforeMount() {
    this.getData()
  },

  methods: {

    getBoatName(boat){
      return this.$t(`Boats.${boat}`)
    },
    getVariantName(boat){
      return this.$t(`Boats.Variants.${boat}`)
    },

    getAvailIndex(boat){
      if(boat.availabilities.length){
        const stock = boat.availabilities[0].stock
        if(stock === 0){
          return "unavailable"
        }
        else if (stock > 2){
          return "available"
        }
        else {
          return "lessavailable"
        }
      }
      else {
        return "unavailable"
      }
    },

   /**
    * -----------
    * DATA CONFIG
    * -----------
    */



    async getData(){

       if(this.$route.path.includes('tomorrow')){
          this.$store.dispatch('navigation/setTomorrow')
        } else {
          if (!this.getDate) {
            this.$router.push('/add-boat/tomorrow')
          }
       }

        
      /* récupérer la liste des boats types */
      const apiBoatData = (await SurfApi.getBoatsTypeList()).data

      /* récupérer la liste des disponibilités */
      const dateInterval = DateService.dayInterval(this.getDate)
      
      const apiAvailData = await SurfApi.getBookinsByDates(dateInterval)

      // dans chaque objet bateau récupéré dans apiBoatData
      // on ajoute une clé "availabilities"
      // -> un array des disponibilités correspondantes récupérées dans apiAvailData
      this.boatData = apiBoatData.map((boat) => {
        const matchingAvail = apiAvailData
            // on récupère les dispos correspondantes
            .filter((avail) => boat.boatsTypeId === avail.boatTypeId)
            // on enlève les données inutiles de l'objet disponibilté pour ne garder que les crénaux horaires
            .map((avail) => avail.availabilities)
            // formattage
            .flat()

            // si le bateau a des sous-catégories, on fait la même chose pour chaque sous-catégorie
            let children = []
            if(boat.children.length > 0){
                children = boat.children.map((child) => {
                  const matchingAvail = apiAvailData
                    .filter((avail) => child.boatsTypeId === avail.boatTypeId)
                    .map((avail) => avail.availabilities)
                    .flat()

                  return ({
                    ...child,
                    availabilities: matchingAvail
                  })
              })
            }

          // ajout de la clé
          this.loading = false
          return ({
            ...boat,
            children: children,
            availabilities: matchingAvail
          })
      })
    },

  /* //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\ */

   /**
    * ----------
    * UI DISPLAY
    * ----------
    */

    selectBoat(e, boat){
      this.selectedBoat = boat
      this.selectedBoat.parentName = ''
      this.$store.dispatch('navigation/setAvailabilities', this.selectedBoat.availabilities)
      this.removeSelectedClassForElements('.boat-container')
      e.target.closest('.boat-container').classList.add('selected')

      if(boat.children.length < 1){
        this.openPopup()
      }
    },
    // selection d'une variante
    selectVariante(e, variant, parentName){
      this.selectedBoat = variant
      this.selectedBoat.parentName = parentName 
      this.$store.dispatch('navigation/setAvailabilities', this.selectedBoat.availabilities)

      this.removeSelectedClassForElements('.sub-category--items')
      e.target.closest('.sub-category--items').classList.add('selected')

      this.openPopup()
    },

    // Open Popup Choix Créneau et Quantity
    openPopup() {
      this.showDetailsPopup = true
    },
    // return only children having availabilities
    getAvailableVariant(boatChildrenList) {
      return boatChildrenList.filter((boatchild) => boatchild.availabilities.length>0)
    },
    removeSelectedClassForElements(className) {
      document.querySelectorAll(className).forEach(element => {
        element.classList.remove('selected')
      });
    }
  },
};
</script>

<style scoped>
.sub-category-text {
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  margin: 1em;
  align-items: center;
}
.index-small{
  height: .7em;
  width: .7em;
  margin-right: 0;
  margin-left: .5rem
}

footer a {
  display: flex;
  align-items: center;
}
</style>