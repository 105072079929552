import { createStore } from "vuex";
import { basket } from './basket.module';
import { navigation } from './navigation.module';


export default createStore({
  modules: {
    basket,
    navigation
  },
});
