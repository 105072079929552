<template>
  <div class="btn-menu--select-language">
    <span class="text">{{ $t("Header.Langue") }}</span>
    <div class="select-language" :class="{'icon-rotate' : drop }" @click="drop=!drop">
      <span class="displayed-locale">{{ $t("locale.full").toUpperCase() }}</span>
      <div class="dropdown" v-show="drop">
        <p v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" @click="changeLocale(locale)">{{ locale.toUpperCase() }}</p>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
      data(){
        return {
          drop: false
        }
      },
      methods: {
        changeLocale(locale){
          this.$i18n.locale = locale
          this.$store.dispatch('navigation/changeLocale', locale)
        }
      }
    }

</script>
