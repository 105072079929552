<template>
  <div class="body body-panier" :class="{ 'disparitionforPopup': showWarningPopup }">

    <header class="components">
      <HeaderComponent :pageTitle="pageTitle"/>
    </header>

    <spinner-component v-show="loadingSpinner"></spinner-component>


    <!-- //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\ -->
    <!-- EXPIRATION DU CART -->

    <!-- Timer expiration du cart -->
    <div class="text need-mT2 colmn" v-if="!isExpired && hasCart && !cartIsEmpty">
      <p> {{ $t("Basket.ExpireDans") }} {{ expirationTimer }} {{ $t("Mot.Minutes") }}</p>
    </div>

    <!-- Message si le cart a expiré -->
    <section class="column">
      <p class="text need-mT4" v-if="isExpired && !cartIsEmpty">{{ $t("Basket.PanierExpiré") }}</p>
      <p class="text need-mT4" v-if="cartIsEmpty">{{ $t("Basket.PanierVide") }}</p>
      <button v-if="isExpired || !hasCart" @click="startNewCart()" class="btn-orange btn need-mTB fit-content">{{ $t("Basket.CommencerUneResa") }}</button>
      <button class="other-boat more-boat" @click="startNewCart()" v-if="cartIsEmpty">{{ $t("Basket.NouveauCart") }}</button>
    </section>


    <!-- //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\ -->
    <!-- PANIER -->
    <table class="basket-container need-mB1" v-if="cart && !cartIsEmpty">
      <thead>
        <tr>
          <th>{{ $t("Mot.Produit") }}</th>
          <th class="text-center">{{ $t("Mot.Quantité") }}</th>
          <th class="text-center">{{ $t("Mot.Prix") }}</th>
          <th></th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="boat of cart.items" :key="boat.id">
          <td class="column">
            <span class="bold need-mB5">{{ $t("Boats."+boat.itemName) }}</span>
            <span class="light fontsize9 need-mB25">{{ ds.format(this.happensOn, "DD MMMM YYYY") }}</span>
            <span class="light fontsize9 need-mB25">{{$t("Basket.Départ")}}: {{ ds.adaptScheduleTime(boat.itemBeginTime) }}</span>
            <span class="light fontsize9">{{$t("Basket.Retour")}}: {{ ds.adaptScheduleTime(boat.itemEndTime) }}</span>
          </td>
          <td class="bold text-center">
            <button class="basket-container--items__supprimer need-mR5 bg-orange" @click="removeBoat(boat,1)" title="Diminuer la quantité" >-</button> 
            {{boat.itemQuantity}}
          </td>
          <td class="bold text-center">{{boat.itemPrice * boat.itemQuantity}} €</td>
          <td>
            <button class="basket-container--items__supprimer bold bg-red" @click="removeBoat(boat,boat.itemQuantity)" title="supprimer la ligne">x</button>
          </td>
        </tr>

        <tr v-for="menu of cart.menus" :key="menu.id">
          <td class="column">
            <span class="bold need-mB5">{{$t("Mot.Menu")}} {{getMenuName(menu.menuName)}}</span>
            <span class="light fontsize9 need-mB25">{{getMealName(menu.menuName, menu.mealName)}}</span>
            <span class="light fontsize9 need-mB25" v-if="menu.drinkName">{{getDrinkName(menu.drinkName)}}</span>
            <span class="light fontsize9" v-if="menu.dessertName">{{getDessertName(menu.dessertName)}}</span>
          </td>
          <td></td>
          <td class="bold text-center">
            {{menu.totalPrice}} €
          </td>
          <td>
            <button class="basket-container--items__supprimer bold bg-red" @click="removeMenu(menu)" title="supprimer la ligne">x</button>
          </td>
        </tr>

      </tbody>
    </table>

    <!-- VIDER PANIER -->
    <div class="container-btn-reset" v-if="!cartIsEmpty">
      <button class="btn-reset fit-content " @click="startNewCart()">{{$t("Basket.ViderPanier")}}</button>
    </div>

    <!-- //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\ -->
    <!-- AJOUTER BATEAU -->
    <p class="text need-mT2" v-show="showMenuSelector && !cartIsEmpty" >{{ $t("Basket.Boat?") }}</p>
    <router-link class="btn-orange fit-content uppercase add-new-boat-btn" to="/add-boat" v-if="!cartIsEmpty && cart">{{ $t("Basket.AjouterBateau") }}</router-link>

    <!-- //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\ -->
    <!-- AJOUTER SNACK -->
    <p class="text need-mT2" v-show="showMenuSelector && !cartIsEmpty" >{{ $t("Basket.Snacks?") }}</p>
    <div class="snack-container" v-show="showMenuSelector && !cartIsEmpty">
        <router-link v-for="menu of availableMenus" :key="menu.id"
          :to="{name: 'choose.snack', params: {type: menu.id}}"
          class="button-snack bg-linear"
        >
            <span :class="'icon icon-menu-'+menu.id"></span>
            <div class="button-snack--title">{{ $t("Mot.Menu") }} <span>{{getMenuName(menu.name)}}s</span></div>
        </router-link>
    </div>

    <!-- //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\ -->
    <!-- PROMO -->
    <div class="price-container column" v-if="cart">
        <div class="promo-container column flex_center row-center need-mB2" v-if="!cartIsEmpty">
          <label for="codepromo" class="need-mR need-mB1">{{ $t("Basket.CodePromo?") }}</label>
          <div class="row flex_center row-center">
            <input class="input-style" type="text" id="codepromo" v-model="promocode" :placeholder="promoPlaceholder"/>
            <button class="btn-orange need-mL" @click="applyPromoCode()">{{ $t("Mot.Appliquer") }}</button>
          </div>
          <p v-if="promoErrorMessage" class="need-mT1"> {{ promoErrorMessage }}</p>
          <div class="text-gradient bold need-mT1" v-if="this.cart.amountPromos > 0 ">{{ $t("Basket.VotreCodePromo") }} : <span class="need-mL5">{{`${cart.promoCode.name}`}}</span></div>
        </div>
        
        <div class="new-price column" >
          <div class="text-gradient bold need-mB2 need-mT1 text-center" :class="{ 'fontsize12' : this.cart.amountPromos > 0 , 'fontsize14' : !this.cart.amountPromos > 0 }" v-if="!cartIsEmpty">{{ $t("Mot.Total") }} {{ $t("Mot.TTC") }} : <span class="need-mL5">{{`${cart.amountTotal} €`}}</span></div>
          <div class="text-gradient fontsize12 bold need-mB1" v-if="!cartIsEmpty && this.cart.amountPromos > 0 ">{{ $t("Basket.Reduction") }} : <span class="need-mL5">{{`${cart.amountPromos} €`}}</span></div>
          <div class="text-gradient fontsize14 bold need-mB4 border-orange" v-if="!cartIsEmpty && this.cart.amountPromos > 0 ">{{ $t("Basket.Montant") }} : <span class="need-mL5">{{`${cart.amountPaid} €`}}</span></div>
        </div>
    </div>

    <!-- //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\ -->
    <!-- PAGINATION -->
    <footer class="pagination" v-show="!cartIsEmpty">
        <router-link to="/" class="pagination-button--return">
          <span class="icon icon-arrow-left1"></span>
          <p class="pagination-button--return__text">{{ $t("Mot.Menu") }}</p>
        </router-link>
        <button @click="showWarningPopup = true" class="pagination-button--next bg-linear" :disabled="expirationTimer <= 0">
          <div class="pagination-button--next__text">{{ $t("Mot.Valider") }}</div>
        </button>
    </footer>
  </div>

  <!-- //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\ -->
  <!-- WARNING POPUP -->
    <div class="bg-body apparitionforPopup" v-show="showWarningPopup"></div>
  <warning-popup
      v-show="showWarningPopup"
      @close="showWarningPopup = false"
  />

</template>

<script>
import { mapGetters } from 'vuex'
import SurfApi from '@/services/surfapi.service'
import DateService from '@/services/date.service'
import dayjs from 'dayjs'

import HeaderComponent from "@/components/elements/HeaderComponent.vue"
import WarningPopup from '@/components/popups/WarningPopup.vue'
import SpinnerComponent from '@/components/elements/SpinnerComponent.vue'


export default {
  name: "BasketView",

  components: {
    HeaderComponent,
    WarningPopup,
    SpinnerComponent
  },

  data(){
    return{
        ds: DateService,
        
      // data
        cart: false,
        availableMenus: [],
        promoCodeValidated:null,

      // UI
        showWarningPopup: false,
        cartIsEmpty : false,
        loadingSpinner : true,
        promocode:null,
        promoErrorMessage: null,
        now: Date.now(),
    }
  },
  created() {
    var self = this
    setInterval(function () {
         self.now = Date.now()
    }, 1000)
  },

  computed: {
    ...mapGetters({
          isExpired: 'basket/isExpired',
          happensOn: 'basket/happensOn',
          hasCart: 'basket/hasCart',
          cartId: 'basket/getCartId',
          expiresOn: 'basket/getExpiration',
    }),

    // trad
    pageTitle(){
      return this.$t("Basket.Title")
    },
    promoPlaceholder(){
      return this.$t("Menus.Placeholder")
    },

    // expiration
    expirationTimer() {
      if (!this.hasCart)
        return 0;
      if (this.isExpired)
        return 0;
      let date1 = dayjs(this.expiresOn)
      return date1.diff(self.now, 'minutes')
    },

    showMenuSelector() {
      return !this.isExpired && this.availableMenus.length > 0    
    },
    
  },

  beforeMount(){
    this.getData()
  },

  methods: {

    // trad
    getBoatName(boat){
      return this.$t(`Boat.${boat}`)
    },
    getMenuName(menu){
      return this.$t(`Menus.${menu}.Title`)
    },
    getMealName(menu, meal){
      return this.$t(`Menus.${menu}.Meal.${meal}`)
    },
    getDrinkName(drink){
      return this.$t(`Menus.Boisson.${drink}`)
    },
    getDessertName(dessert){
      return this.$t(`Menus.Dessert.${dessert}`)
    },
    
    // data
    async getData(){
      // check si l'utilisateur à une commande en attente
      if(this.hasCart && !this.isExpired){
        // invalid date bug tracker
        if(this.ds.format(this.happensOn,"YYYY-MM-DD") === "Invalid Date"){
          console.error("Bug Tracker : Invalid Date in BasketView")
          console.info("Cart : ", this.cart)
        }

        this.cart = await (await SurfApi.getCart(this.cartId)).data
        // récupère les menus disponibles à la date correspondante
        const date = DateService.formatForApi(this.happensOn)
        const menus = await SurfApi.getMenus(date)
        this.availableMenus = menus.data
        this.cartIsEmpty = this.cart.items.length == 0 && this.cart.menus.length == 0
        this.$store.dispatch('basket/updateExpiration', this.cart.expiration)
      }
      
      this.loadingSpinner = false
    },

    addOtherBoat(){
      if(DateService.checkTomorrow(this.date)){
        this.$router.push('/add-boat/tomorrow')
      }
      else {
        this.$router.push('/add-boat')
      }
    },

    async applyPromoCode() {
      if (this.promocode) {
        let response = await SurfApi.getPromoCode(this.promocode)
        let promoCode = response.data
        if (promoCode.isAccepted) {
          this.promoCodeValidated = promoCode.promoCode
          let payload = {
            promoCode : promoCode.promoCode.name
          }
          this.cart = (await SurfApi.applyPromoCode(this.cart.cartId, payload)).data;
          this.promoErrorMessage = ""
        } else {
          this.promoErrorMessage = this.$t("Basket.PromoErrorMessage")
        }
      }
    },

    /* Cart isExpired ou action volontaire */
    startNewCart() {
      if (!this.isExpired) {
        if (this.cart.items) {
          this.cart.items.forEach((boat) => {
            this.removeBoat(boat, boat.itemQuantity);
          })
        }
        if (this.cart.menus) {
           this.cart.menus.forEach((menu) => {
            this.removeMenu(menu);
          })
        }
        
      }
      this.$store.dispatch('basket/reset')
      this.$router.push('/')
    },

    async removeBoat(boat, quantity){
      const data = {
        orderId: this.cart.cartId,
        scheduleId: boat.itemScheduleId || boat.itemId,
        quantity: quantity
      }

      await SurfApi.removeSchedule(data.orderId, data.scheduleId, data.quantity)
      const cart = await SurfApi.getCart(this.cart.cartId)
      this.cart = cart.data
      this.cartIsEmpty = this.cart.items.length == 0 && this.cart.menus.length == 0
    },

    async removeMenu(menu){
      const data = {
        orderId: this.cart.cartId,
        orderMenuId: menu.orderMenuId
      }

      await SurfApi.removeMenu(data.orderId, data.orderMenuId)
      const cart = await SurfApi.getCart(this.cart.cartId)
      this.cart = cart.data
      this.cartIsEmpty = this.cart.items.length == 0 && this.cart.menus.length == 0
    },

  }
}
</script>

<style lang="scss" scoped>
.add-new-boat-btn{
  margin-bottom: 1em;
}
.container-btn-reset{
  height: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1em;
  button {
    font-size: 1rem;
  }
}
</style>