export default {
  "1)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["___________________ GLOBALS ___________________"])},
  "locale": {
    "full": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["english"])}
  },
  "2)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["____________________ VIEWS ____________________"])},
  "App": {
    "MajDispo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An update is available"])},
    "MettreAJour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])}
  },
  "Calendar": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a boat & a day"])},
    "ChoixJour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a day"])},
    "Warning1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You already have a booking for"])},
    "Warning2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". If you select another date, your current booking will be reset."])}
  },
  "AddBoat": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book a boat"])},
    "ChoixType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a type"])},
    "NoDispoDemain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, we don't have any availabilities for tomorrow"])},
    "AutreRésa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book for another day"])},
    "TrouvePasBonheur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can't find what you are looking for ?"])},
    "QuePourDemain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["These are only tomorrow's availabilities"])},
    "PeutRésaAutreJour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can book for another day"])},
    "Error1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You already have a booking planned for "])},
    "Error2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in your cart. To start a new booking will erase the previous one."])}
  },
  "Snack": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compose your menu"])},
    "Info": {
      "MiniInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All of our products are fresh and carefully packed !"])},
      "ShowInfos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more"])},
      "Popup": {
        "Header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About our snacks"])},
        "Title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surf Center"])},
        "Title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation"])},
        "Content1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take it easy !"])},
        "Content2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can order snacks online. Every morning fresh and delicious carefully packed-lunch are prepared for you, so you can enjoy your day at the fullest!"])},
        "Content3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You'll find a large part of our restaurant menu : sandwichs, salads, wraps, soda and home-made deserts. Your meal is prepared before your departure and put in an isothermal bag with ice pack to keep it fresh."])}
      }
    },
    "Snack?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which meal ?"])},
    "Ingrédients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingredients"])},
    "Allergènes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allergens"])},
    "Boisson?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which drink ?"])},
    "Dessert?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which desert ?"])},
    "Dessert": {
      "Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All our desserts are homemade"])}
    },
    "AjouterPanier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to cart"])}
  },
  "Basket": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your cart"])},
    "PanierExpiré": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your cart has expired :("])},
    "PanierVide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your cart is empty ! Click on the button to start booking :)"])},
    "CommencerUneResa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start booking"])},
    "Départ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Departure"])},
    "Retour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return"])},
    "ViderPanier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty basket"])},
    "ExpireDans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your cart wil expire in "])},
    "CodePromo?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have a promo code ?"])},
    "VotreCodePromo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your promo code"])},
    "PromoErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, this code is not valid"])},
    "Reduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount"])},
    "Montant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount to pay"])},
    "Boat?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to add another boat ?"])},
    "Snacks?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to add any snacks ?"])},
    "AjouterBateau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a boat"])},
    "NouveauCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New booking"])}
  },
  "Succes": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "Merci": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you !"])},
    "MailInfos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will receive additional informations by mail."])},
    "SiQuestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any question ? Check"])},
    "NotreFAQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["our FAQ"])},
    "ABientot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See you soon !"])},
    "AutreRésa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start another booking"])}
  },
  "Cancel": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "Dommage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops !"])},
    "PaimentError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Looks like payement could not be finalized :'("])},
    "SiQuestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any question ? Check"])},
    "NotreFAQ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["our FAQ"])},
    "Reessayer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retry"])},
    "AutreRésa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start another booking"])}
  },
  "Formulaire": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your informations"])},
    "Placeholder1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write here"])},
    "Placeholder2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optional - max 255 characters"])},
    "Nom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
    "Prénom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
    "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "IndexTéléphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telephone index"])},
    "ChoixListe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select in the list"])},
    "Téléphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telephone"])},
    "Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave us a message"])},
    "ErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill all fields with *"])},
    "ExpireDans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your cart will expire in"])},
    "CommenceRésa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start booking"])},
    "PanierExpiré": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your cart has expired, please try to book again."])},
    "CaracteresMax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["characters remaining"])}
  },
  "3)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["___________________ COMPOSANTS ___________________"])},
  "Header": {
    "Location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation"])},
    "Bienvenue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to Surf Center's booking website !"])},
    "ResaJour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a day"])},
    "ResaLendemain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book for tomorrow"])},
    "Panier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My cart"])},
    "RetourSite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to website"])},
    "Langue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])}
  },
  "ResaDetail": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your order details"])},
    "ChoixCréneau": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a time slot"])},
    "StockWarning": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["available"])},
      "pluriel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "Quantité": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["number of boats :"])},
    "CreneauPasSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a time slot"])}
  },
  "WarningPopup": {
    "Title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surf Center"])},
    "Title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservation"])},
    "Important1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Important"])},
    "Important2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to know"])},
    "Warning1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are about to pay for your reservation."])},
    "Warning2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please make sure you didn’t make any mistakes."])},
    "Warning3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note that due to high tourist numbers, no changes in date or type of booking will be possible and no refunds will be made, except in case of weather conditions that force us to leave our boats on land (strong wind, storm, ...)."])}
  },
  "4)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["____________________ COMMONS ____________________"])},
  "Boats": {
    "undefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "Pédalos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedal boat slide 5 person"])},
    "Pédalo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedal boat slide 5 person"])},
    "Paddle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paddle 1 person"])},
    "Bateaux Électriques": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electric boat 6 person"])},
    "Canoë": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canoe"])},
    "Canoë 1 place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canoe 1 place"])},
    "Canoë 2 places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canoe 2 places"])},
    "Canoë 3 places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canoe 3 places"])},
    "Variants": {
      "1 place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 place"])},
      "2 places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 places"])},
      "3 places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 places"])}
    },
    "1 place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 place"])},
    "2 places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 places"])},
    "3 places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 places"])}
  },
  "Menus": {
    "Placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write here"])},
    "Salade": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salad"])},
      "Meal": {
        "Salade Pêcheuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fisherman's Salad"])},
        "Salade Poulette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chicken Salad"])},
        "Salade Sicilienne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sicilian Salad"])},
        "Végé Bowl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veggie Bowl"])}
      }
    },
    "Sandwich": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sandwich"])},
      "Meal": {
        "Chicken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chicken"])},
        "Wrap Poséidon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poseidon Wrap"])},
        "Italien": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italian"])},
        "Original": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Original"])},
        "Wrap Végé": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veggie Wrap"])}
      }
    },
    "Boisson": {
      "Coca": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coke"])},
      "Coca Zéro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coke Zero"])},
      "Orangina": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orangina"])},
      "Stella Artois 33cl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stella Artois 33cl"])},
      "Leffe 33cl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leffe 33cl"])},
      "SurfCenter IPA 33cl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SurfCenter IPA 33cl"])},
      "Eau plate 50cl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Still water 50cl"])},
      "Perrier 33cl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perrier 33cl"])},
      "Limonade 25cl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lemonade 25cl"])}
    },
    "Dessert": {
      "Brownie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brownie"])},
      "Cookie Chocolat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chocolate Cookie"])},
      "Financier Myrtilles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blueberry Financier"])}
    }
  },
  "Ingrédients": {
    "Saumon fumé": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smoked salmon"])},
    "Concombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cucumber"])},
    "Tomate cerise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cherry tomato"])},
    "Oignon rouge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Red onion"])},
    "Fusilli": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fusilli"])},
    "Sauce blanche": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["White sauce"])},
    "Poulet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chicken"])},
    "Parmesan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parmesan"])},
    "Tomates cerises": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cherry tomatoes"])},
    "Oignons frits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fried onions"])},
    "Jambon cru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raw ham"])},
    "Fusilli au pesto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fusilli with pesto"])},
    "Tomates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tomatoes"])},
    "Quinoa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quinoa"])},
    "Avocat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avocado"])},
    "Carotte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carrot"])},
    "Féta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feta cheese"])},
    "Cacahuète": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peanuts"])},
    "Olives noires": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Black olives"])},
    "Tomate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tomatoes"])},
    "Salade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salad"])},
    "Saumon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salmon"])},
    "Fromage à tartiner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cheese spread"])},
    "Chèvre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goat cheese"])},
    "Pesto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesto"])},
    "Tomate confite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sundried tomato"])},
    "Jambon blanc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ham"])},
    "Emmental": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emmental"])},
    "Noix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuts"])}
  },
  "Allergènes": {
    "Gluten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gluten"])},
    "Lait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milk"])},
    "Poisson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fish"])},
    "Fruits à coque": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuts"])}
  },
  "5)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["_________________ MOTS ISOLÉS _________________"])},
  "Mot": {
    "Retour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "Valider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate"])},
    "Continuer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "Menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])},
    "Prix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
    "Produit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product(s)"])},
    "Quantité": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
    "Minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minutes"])},
    "Appliquer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
    "Payer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay"])},
    "Total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "TTC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT"])},
    "Aucun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])}
  },
  "6)": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["_________________ MESSAGES DIVERS _________________"])},
  "Messages": {
    "SiteWeb": {
      "Texte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to know more about our nautical supports?"])},
      "Lien": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visit our presentation website"])}
    }
  }
}