import api from './api';
import DateService from './date.service';

class SurfApi {

    /* Availabilities */

    // pour le lendemain
    getTomorrowBookings = () =>  api.get(`availabilities/tomorrow`)

    // AddBoatView
    // pour un jour ou interval de jour particulier
    // les dates envoyées par l'API sont formattées avant transmission à la vue
    async getBookinsByDates(datesToSend){
        const data = await api.post(`availabilities/`, datesToSend)
        const availabilities = data.data.map((d) => {
            return ({
                ...d,
                availabilities: DateService.formatDateFromApi(d.availabilities)})
        })
        return availabilities
    }

    // CalendarVie w
    // pour un jour ou interval de jour particulier en fonction d'un type de bateau
    // les dates envoyées par l'API sont formattées avant transmission à la vue
    async getBookinsByDatesAndBoatId(boatId, datesToSend){
        const data = await api.post(`availabilities/${boatId}`, datesToSend)
        const formatted = DateService.formatDateFromApi(data.data[0].availabilities)
        return formatted
    }

    /* //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\ */
    
    /* Boats */
    
    // get data
    getBoatsTypeList = () => api.get(`boats/types`)
        
    getBoatDetailsById = (boatId) => api.get(`boats/types/${boatId}`)
    
    getBoatVariantsById = (boatId) => api.get(`boats/types/${boatId}/variants`)
    

    // add boat to existing cart
    addBoat = (cartId, booking) => api.post(`Order/${cartId}/add`, booking)
    

    /* //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\ */

    /* Snacks */

    // menus
    getMenus= (date) => api.get(`Menus/Menus`, {params: {date: date}} )
    
    
    // produits
    getProducts = (menuId) => api.get(`Menus/Products/${menuId}`)
    

    // add menu to existing cart
    addMenu= (cartId, menu) => api.post(`Order/${cartId}/addMenu`, menu)
    

    /* //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\ */

    /* Cart */

    getPromoCode = (payload) =>  api.get(`promo/${payload}`)

    applyPromoCode = (orderId, payload) =>  api.post(`order/${orderId}/promo`, payload)
    
    createCart = (payload) => api.post('order', payload)
    
    
    getCart= (cartId) =>  api.get(`order/${cartId}`)
    

    cartHasExpired= (cartId) => api.get(`order/${cartId}/expiration`)
    


    removeSchedule= (cartId, scheduleId, quantity) => api.post(`/order/${cartId}/removeSchedule/${scheduleId}/${quantity}`)
    

    removeMenu= (cartId, orderMenuId) => api.post(`/order/${cartId}/removeMenu/${orderMenuId}`)
    

    validateCart = (cartId, data) => api.post(`/order/${cartId}/validate`, data)
    
    retryPayment = (cartId) => api.post(`/order/${cartId}/retry`)

    
    successCart(cartid, token) {
        let tokenObj = {
            "stripetoken" : token
        };
        return api.post( `/order/${cartid}/success` , tokenObj)
    }
}

export default new SurfApi();