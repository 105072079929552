import { createRouter, createWebHistory } from "vue-router"

import HomeView from "../views/v1/HomeView.vue"
import CalendarView from "../views/v1/CalendarView.vue"
import AddBoatView from "../views/v1/AddBoatView.vue"
import SnackView from "../views/v1/SnackView.vue"
import BasketView from "../views/v1/BasketView.vue"
import FormulaireView from "../views/v1/FormulaireView.vue"
import SuccesView from '../views/v1/SuccesView.vue'
import CancelView from '../views/v1/CancelView.vue'

import ServiceWorkerService from '../services/sw.service'

const routes = [

  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/calendar",
    name: "calendar",
    component: CalendarView,
  },
  {
    path: "/add-boat",
    name: "add.boat",
    component: AddBoatView,
  },
  {
    path: "/add-boat/tomorrow",
    name: "tomorrow",
    component: AddBoatView,
  },
  {
    path: "/snack/:type",
    name: "choose.snack",
    component: SnackView,
  },
  {
    path: "/basket",
    name: "basket",
    component: BasketView,
  },
  {
    path: "/formulaire",
    name: "formulaire",
    component: FormulaireView,
  },
  {
    path: "/success",
    name: "success",
    component: SuccesView,
  },
  {
    path: "/cancel",
    name: "cancel",
    component: CancelView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(() => {
  if('serviceWorker' in navigator){
    console.log('navigation check')
    ServiceWorkerService.checkUpdate()
  }
})

export default router;
