<template>
  <div class="spinner-container">
    <div class="loadingio-spinner-spinner-0aa6dy125dwf"><div class="ldio-2dhx7kbzeoh">
    <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>
  </div>
</template>
<script>
  export default {
    name: 'SpinnerComponent'
  }
</script>