<template>
  <div class="body">
    <header class="components">
      <HeaderComponent :pageTitle="pageTitle"/>
    </header>
    
    <section class="column" v-if="this.isExpired || !this.hasCart">
      <p class="text need-mT need-mB2">{{$t("Formulaire.PanierExpiré")}}</p>
      <button  @click="startNewCart()" class="btn-orange btn need-mTB fit-content">{{$t("Formulaire.CommenceRésa")}}</button>
    </section>
    
    <form method="post" @submit.prevent="validate()"> 
     <div class="text need-mT2 need-mB2" v-if="!isExpired && hasCart">
      <p> {{$t("Formulaire.ExpireDans")}} {{ expirationTimer }} {{$t("Mot.Minutes")}}</p>
    </div>
        <div class="column item-form">
            <label for="name" class="item-form--text">{{ $t("Formulaire.Nom") }} *</label>
            <input type="text" id="lastname" class="form" name="user_name" :placeholder="placeHolder1" v-model="givenName" required :disabled="this.isExpired">
        </div>
        <div class="column item-form">
            <label for="first-name" class="item-form--text">{{ $t("Formulaire.Prénom") }} *</label>
            <input type="text" id="firstname" class="form" name="user_name" :placeholder="placeHolder1" v-model="firstName" required :disabled="this.isExpired">
        </div>

        <div class="column item-form">
            <label for="mail" class="item-form--text">{{ $t("Formulaire.Email") }} *</label>
            <input type="email" id="email" class="form" name="user_mail" :placeholder="placeHolder1" v-model="email" required :disabled="this.isExpired">
        </div>
        <div class="column item-form relative">
          <label for="cellcountrycode" class="item-form--text">{{ $t("Formulaire.IndexTéléphone") }} * <span class="light italic">{{ $t("Formulaire.ChoixListe") }}</span></label>
          <select class="form" id="cellcountrycode" v-model="cellphoneCountryCode" required :disabled="this.isExpired">
            <option v-for="data, index in countrycodes" :value="data.countryCode" :key="index">{{data.country}} (+{{data.countryCode}})</option>
          </select>
          <span class="select-arrow icon-arrow-right"></span>
        </div>
        <div class="column item-form">
            <label for="tel" class="item-form--text">{{ $t("Formulaire.Téléphone") }} *</label>
            <input type="tel" class="form" id="cellphone" name="tel" :placeholder="placeHolder1" pattern="[0-9]{9,10,11,12}" v-model="cellphone" required :disabled="this.isExpired">
        </div>

        <div class="column item-form">
            <label for="message" class="item-form--text">{{ $t("Formulaire.Message") }}</label>
            <textarea class="form message" name="message" :placeholder="placeHolder2" v-model="message"  maxlength="220"  :disabled="this.isExpired"></textarea>
            <p class="need-mT5 self-flexend fontsize8">{{charactersLeft}}</p>
        </div>
        
        <p class="text error" v-show="errorMessage">{{errorMessage}}</p>

        <section class="pagination">
            <go-back returnLink="/basket" :returnTitle="retour" />
            <button  type="submit" class="pagination-button--next bg-linear relative" :disabled="validationSpinner || isExpired">
                <div class="pagination-button--next__text">{{ $t("Mot.Payer") }}</div>
                <spinner-component class="absolute-btn-spinner" v-show="validationSpinner"></spinner-component>
            </button>
        </section>
    </form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HeaderComponent from "@/components/elements/HeaderComponent.vue";
import GoBack from "@/components/elements/GoBack.vue";
import SurfApi from "@/services/surfapi.service";
import countrycodes from '@/data/countrycodes.json';
import SpinnerComponent from '@/components/elements/SpinnerComponent.vue'
import dayjs from 'dayjs'


export default {
  name: "FormulaireView",
  components: {
    HeaderComponent,
    GoBack,
    SpinnerComponent
  },

  data(){
    return {
      firstName: '',
      givenName: '',
      email: '',
      cellphone: '',
      cellphoneCountryCode: 0,
      message: '',
      errorMessage: false,
      countrycodes: countrycodes,
      validationSpinner: false,
      basketMessage: '',

    }
  },

  computed: {
    pageTitle(){return this.$t("Formulaire.Title")},

    placeHolder1(){ return this.$t("Formulaire.Placeholder1")},
    placeHolder2(){ return this.$t("Formulaire.Placeholder2")},

    retour(){return this.$t("Mot.Retour")},

    ...mapGetters({
          isExpired: 'basket/isExpired',
          happensOn: 'basket/happensOn',
          hasCart: 'basket/hasCart',
          cartId: 'basket/getCartId',
          expiresOn: 'basket/getExpiration'
    }),

    expirationTimer() {
      if (!this.hasCart)
        return 0;
      if (this.isExpired)
        return 0;
      let date1 = dayjs(this.expiresOn)
      return date1.diff(self.now, 'minutes')
    },
    
    //Compteurs lettres textaera
    charactersLeft() {
        var char = this.message.length,
            limit = 220;

        return (limit - char) + " / " + limit + " " + this.$t("Formulaire.CaracteresMax");
      }
  },

  methods: {
    validEmail(email){
      let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[\d]{1,3}\.[\d]{1,3}\.[\d]{1,3}\.[\d]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    checkPhone(phone){
      return !!phone
    },
    startNewCart() {
      this.$store.dispatch('basket/reset')
      this.$router.push('/')
    },

    async validate() {
      if (!this.firstName || !this.givenName || !this.validEmail(this.email) || !this.checkPhone(this.cellphone)) {
        this.errorMessage = this.$t("Formulaire.ErrorMessage")
      }
      else {
        this.validationSpinner = true

        const data = {
          name: this.firstName,
          surname: this.givenName,
          email: this.email,
          phoneNumber: this.cellphone,
          phoneNumberPrefix: this.cellphoneCountryCode,
          message: this.message,
          Locale: this.$store.state.navigation.locale.toUpperCase()
        }

        this.sendValidation(data)
      }
    },
      async sendValidation(data){
        const response = await SurfApi.validateCart(this.cartId, data)
        if(response.data.redirect) window.location.href = response.data.redirect;
      }      
    },
};
</script>

<style>
  .error{
    color: firebrick
  }
</style>