<template>
    <div class="template-container month-calendar-month ApparitionCalendarWeek" :class="{ 'with-visible-popup': showDetailsPopup }">

        <!-- //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\ -->
        <!-- HEADER -->
        <header class="components">
            <HeaderComponent :pageTitle="pageTitle"/>
        </header>

        <p class="text-warning need-mB2" v-show="this.hasCart && !this.isExpired">{{ $t('Calendar.Warning1') }} {{ds.format(this.happensOn, "dddd DD MMMM YYYY")}} {{ $t('Calendar.Warning2') }}</p>

        <spinner-component v-show="loadingSpinner"></spinner-component>

        <main class="boat-container-index">
            
            <!-- //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\ -->
            <!-- SELECTION BATEAU -->
            <div class="div-boat-container column">
                <div v-for="boatType in boatList" :key="boatType.index" class="boat-container">

                    <button class="boat-container" :id="boatType.boatsTypeId" @click="selectBoat(boatType)"
                        :class="{selected: selectedBoat.boatsTypeId === boatType.boatsTypeId ||
                                        selectedCategory.boatsTypeId === boatType.boatsTypeId}">
                        <div class="boat-container--left">
                            <div class="boat-logo bg-linear">
                                <span class="icon" :class="'icon-boat' + boatType.boatsTypeId"></span>
                            </div>
                            <p class="boat-text">{{ $t('Boats.'+boatType.name) }}</p>
                        </div>
                    </button>

                </div>
            </div>

            <div class="div-variant-container">
                <button v-for="variant of selectedCategory.children" :key="variant.id"
                    class="boat-container" :id="variant.boatsTypeId" 
                    :class="{selected: selectedBoat.boatsTypeId === variant.boatsTypeId}"
                    @click="selectVariant(variant, selectedCategory.name)"
                >
                    <div class="boat-container--left">
                        <div class="boat-logo bg-linear">
                            <span class="icon" :class="'icon-boat' + variant.boatsTypeId"></span>
                        </div>
                        <p class="boat-text">{{ $t("Boats.Variants."+variant.name) }}</p>
                    </div>
                </button>
            </div>

            <p class="text" v-show="showCalendar">{{ $t("Calendar.ChoixJour") }}</p>

            <div class="container-calendar" v-show="showCalendar">

                <!-- //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\ -->
                <!-- SELECTION DATE-->
                <section class="container-select-week">
                    <div class="select-weekormonth">
                        <button class="select-weekormonth--day" id="present-month-selector" @click="setCurrentMonth()">
                            {{ ds.format(today, "DD MMMM") }}
                        </button>

                        <header class="header">
                            <button class="select-weekormonth--button bg-linear" id="previous-month-selector"
                                @click="setPreviousMonth()"
                            >
                                <span class="icon icon-arrow-left1"></span>
                            </button>

                            <p id="selected-month" class="select-weekormonth--monthyears calendar-month-header-selected-month">
                                {{ ds.displayMonth(this.calendar.month[0]) }}
                            </p>

                            <button class="select-weekormonth--button next bg-linear" id="next-month-selector"
                                @click="setNextMonth()"
                            >
                                <span class="icon icon-arrow-right"></span>
                            </button>
                        </header>

                    </div>
                </section>

                <!-- //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\ -->
                <!-- CALENDRIER -->
                <div class="month-calendar-content" v-if="calendar.calendar" id="month-calendar-id">
                    <ol id="days-of-week" class="day-of-week">
                        <li v-for="day of calendar.weekDays" :key="day.id">{{ ds.displayDayName(day) }}</li>
                    </ol>

                    <ol id="calendar-days" class="days-grid" :key="calendarKey">
                        <li v-for="row of calendar.calendar" :key="row.id">
                            <ol class="calendar-row">
                                <li v-for="day of row" :key="day.id" class="calendar-day hidden"
                                    v-on=" (day.stock && !day.fromOtherMonth) ? { click:()=> selectCalendarDay(day) } : {}"
                                >
                                    <div class="availability-bg"
                                        :class="{
                                            'available': day.stock>2 && !day.fromOtherMonth,
                                            'lessavailable': day.stock>0 && day.stock<3 && !day.fromOtherMonth,
                                            'unavailable': !day.stock && !day.fromOtherMonth && !day.isPastDay,
                                            'not-selectable' : !day.stock || day.fromOtherMonth || day.isPastDay
                                        }"
                                    >
                                        <span class="calendar-number" :class="{'from-other-month': day.fromOtherMonth}">{{ toCalendarDay(day.date) }}</span>
                                    </div>
                                </li>
                            </ol>
                        </li>
                    </ol>

                </div>
            </div>
            <aside class="text-info need-mT4">
                <p>{{ $t("Messages.SiteWeb.Texte")}} <a href="https://surfcenter.fr/nos-locations/" target="_blank" class="common-link color-blue italic">{{ $t("Messages.SiteWeb.Lien")}}</a></p>
            </aside>
        </main>


        <!-- //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\ -->
        <!-- PAGINATION -->
        <footer class="pagination">
            <router-link to="/" class="pagination-router-link--return">
                <span class="icon icon-arrow-left1"></span>
                <p class="pagination-button--return__text">{{ $t("Mot.Retour") }}</p>
            </router-link>
        </footer>


        <!-- //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\ -->
        <!-- POPUPS -->

        <!-- Bg derrière pour le border-radius du Pop-up -->
        <div class="bg-body apparitionforPopup" v-show="showDetailsPopup"></div>

        <resa-details-popup
            v-show="showDetailsPopup"
            :category="selectedCategory"
            :boat="selectedBoat"
            @close="showDetailsPopup = false"
        />

    </div>

</template>

<script>
import { nextTick } from 'vue'
import { mapGetters } from 'vuex'
import SurfApi from '@/services/surfapi.service'
import DateService from '@/services/date.service'
import Calendar from '@/services/calendar.service'

import HeaderComponent from "@/components/elements/HeaderComponent.vue"
import ResaDetailsPopup from '@/components/popups/ResaDetailsPopup.vue'
import SpinnerComponent from '@/components/elements/SpinnerComponent.vue'


export default {
    components: {
        HeaderComponent,
        ResaDetailsPopup,
        SpinnerComponent,
    },

    data() {
        return {
            ds: DateService,
            calendar: new Calendar(),
            calendarKey: 0,
            showCalendar: true,
            today: DateService.getToday(),

            boatList: [],
            monthAvailabilities: [],

            selectedCategory: false,
            selectedBoat: false,

            showDetailsPopup: false,
            errorMessage:null,

            loadingSpinner : true,
        }
    },
    computed: {
        ...mapGetters({
            isExpired: 'basket/isExpired',
            happensOn: 'basket/happensOn',
            hasCart: 'basket/hasCart',
            getDate: 'navigation/getDate'
        }),

        // trad
        pageTitle(){ return this.$t('Calendar.Title')},
        retour(){ return this.$t('Mot.Retour')},
    },

    // API call 
    beforeMount() {
        this.getConfig()
    },

    methods: {

        /**
         * ------
         * Config
         * ------
         */

        async getConfig(){
            let boats = (await SurfApi.getBoatsTypeList()).data

            this.boatList = boats
            this.selectedBoat = boats[0]

            this.monthAvailabilities = await SurfApi.getBookinsByDatesAndBoatId(this.selectedBoat.boatsTypeId, this.calendar.monthInterval())
            this.loadingSpinner = false
            this.reRenderCalendar()
        },

        /* //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\// */

        /**
         * -------
         * Bateaux
         * -------
         */

        async selectBoat(boat){
            
            if(boat.children.length == 0){
                
                await this.fadeOutAnim()
                document.querySelectorAll('.div-variant-container.open').forEach((el) => {
                    el.classList.remove('open')
                })
                this.showCalendar = true
                this.scrollToCalendar()
                this.selectedCategory = false
                this.selectedBoat = boat
                this.selectedBoat.parentName = '';
    
                this.monthAvailabilities = await SurfApi.getBookinsByDatesAndBoatId(this.selectedBoat.boatsTypeId, this.calendar.monthInterval())
                this.reRenderCalendar()
            }
            else {
                this.selectedCategory = boat
                this.showCalendar = false
                this.selectedBoat = false
                document.querySelectorAll('.div-variant-container').forEach((el) => {
                    el.classList.add('open')
                })
            }
        },

        async selectVariant(boat, parentName){
            this.selectedBoat = boat
            this.selectedBoat.parentName = parentName;

            this.monthAvailabilities = await SurfApi.getBookinsByDatesAndBoatId(this.selectedBoat.boatsTypeId, this.calendar.monthInterval())
            this.showCalendar = true
            this.scrollToCalendar()
            await this.fadeOutAnim()
            this.reRenderCalendar()
        },

        /* //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\// */

        /**
         * ----------
         * Calendrier
         * ----------
         */

        async setCurrentMonth(){
            await this.fadeOutAnim()
            
            this.calendar.reset()

            this.monthAvailabilities = await SurfApi.getBookinsByDatesAndBoatId(this.selectedBoat.boatsTypeId, this.calendar.monthInterval())
            this.reRenderCalendar()
        },

        async setPreviousMonth() {
            await this.fadeOutAnim()

            this.calendar.setPreviousMonth()

            this.monthAvailabilities = await SurfApi.getBookinsByDatesAndBoatId(this.selectedBoat.boatsTypeId, this.calendar.monthInterval())
            this.reRenderCalendar()
        },

        async setNextMonth() {
            await this.fadeOutAnim()

            this.calendar.setNextMonth()

            this.monthAvailabilities = await SurfApi.getBookinsByDatesAndBoatId(this.selectedBoat.boatsTypeId, this.calendar.monthInterval())
            this.reRenderCalendar()
        },

        selectCalendarDay(day) {
            if (!day.fromOtherMonth) {
                this.$store.dispatch('navigation/setDate', day.date)
                this.$store.dispatch('navigation/setAvailabilities', day.avails)
                this.showDetailsPopup = true
            }
            else {
                if(day.date.format("DD")>24){
                    this.setPreviousMonth()
                }
                else if(day.date.format("DD")<10) {
                    this.setNextMonth()
                }
            }
        },

        /* //\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\//\\// */

        /**
         * --------------
         * UI & Animation
         * --------------
         */

        adaptTime(time) {
            return time.replace(':', 'h').split(':')[0]
        },

        toCalendarDay(day){
            return DateService.toCalendarDay(day)
        },

        async fadeOutAnim(){
            document.querySelectorAll('.availability-bg').forEach((el) => {
                el.classList.remove('show')
            })
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve()
                }, 200); // 200 = animation time /!\ hardcoding
            })
        },

        fadeInAnim(){
            document.querySelectorAll('.availability-bg').forEach((el) => {
                el.classList.add('show')
            })
        },

        scrollToCalendar(){
            var elem = document.getElementById("month-calendar-id");
            elem.scrollIntoView({behavior: 'smooth'});
        },

        async reRenderCalendar(){
            
            // Calendrier = [[lundi, mardi, ...], [lundi, mardi, ...], ...]
            // Jour = dayjsObject

            // On veut ajouter à chaque jour une clé "avails" qui liste les différents créneaux horaires correspondants
            // Et une clé "isAvailable" si il y a au moins 1 disponibilité (pour affichage)

            // Jour -> {date: dayjsObject, avails: [[avail], [avail], ...], isAvailable: true || false}
            
            const cal2 = this.calendar.calendar
                // écrase l'array -> Calendrier = [jour, jour, jour, ...]
                .flat()
                // on transforme chaque jour en objet et on ajoute des clés
                .map((day) => {
                    // récupère les créneaux disponibles correspondants
                    const avails = this.monthAvailabilities.filter((availability) => {
                        return DateService.isSameDate(availability.date, day.date)
                    })

                    const stock = avails[0] ? avails[0].stock : false
                    const isPastDay = DateService.isPastDay(day.date) || DateService.checkToday(day.date)

                    return ({
                        ...day,
                        avails: avails,
                        stock: stock,
                        isPastDay: isPastDay
                    })
                })
                // reconstruit le calendrier en semaine -> Calendrier = [[lundi, mardi, ...], [lundi, mardi, ...], ...]
                .reduce((resultArray, item, index) => { 
                    const chunkIndex = Math.floor(index/7) // détermine la taille du chunk
                    if(!resultArray[chunkIndex]) {
                        resultArray[chunkIndex] = [] // start a new chunk
                    }
                    resultArray[chunkIndex].push(item)
                    return resultArray
                }, [])

            this.calendar.calendar = cal2

            // On modifie la clé du "composant" calendrier pour forcer un nouveau rendu
            // et afficher les disponibilités à chaque changement de bateau ou de date
            // https://michaelnthiessen.com/force-re-render/
            this.calendarKey+=1

            // Animation -> on attend le prochain tick = on attend le rerendu
            await nextTick()
            this.fadeInAnim()
        },

    },
};
</script>


<style lang="scss" scoped>

// UI
#selected-month {
    //width: 13em;
    height: 2.6em;
    margin: 0 .5em;
    background: white;
    border-radius: 4px;
    display: flex;
    align-items: center;
}



// calendar
ol {
    padding: 0;
}

#calendar-days {
    display: flex;
    flex-direction: column;
}

.calendar-row {
    display: flex;
    justify-content: space-around;
}

.availability-bg{
    cursor: pointer;
    transition: .2s;
    //width: 3em;
    border-radius: 4px;
    opacity: 0;
    position: absolute;
    z-index: 1;
    //height: 3em;
    padding: .75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    &.not-selectable{
        cursor:default;
    }
    &.show{
        opacity: 1;
        &.available{
            color:white;
            &:hover{
                color:#1CC972;
                background: white;
                border: 2px solid #1CC972
            }
        }
    }
    &.available{
        background-color: #1CC972;
    }
    &.lessavailable{
        background-color: orange;
        color:white;
        &:hover{
            color:orange;
            background: white;
            border: 2px solid orange;
        }
    }

    &.unavailable {
        color: white
    }
}



.calendar-number {
    position: relative;
    z-index: 2;
}

.from-other-month {
    opacity: .2
}

.div-variant-container {
    height: 0px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .boat-container{
        width: 40%;
    }
    &.open {
        height: unset;
    }
}

footer a{
    display: flex;
    align-items: center;
}
</style>
